import { FC, Fragment } from "react";
import { Listbox, Transition } from "@headlessui/react";
import { CheckIcon, ChevronDownIcon } from "@heroicons/react/20/solid";

function classNames(...classes: any) {
  return classes.filter(Boolean).join(" ");
}

export type DropdownMenuProps = {
  /**
   * If dropdown should be disabled
   */
  disabled?: boolean;
  /**
   * Label to be displayed above dropdown
   */
  label?: string;
  /**
   * Array of values to be displayed inside the dropdown
   */
  values: Array<string>;
  /**
   * Currently selected value
   */
  selected: string;
  /**
   * Function that sets currently selected value
   */
  setSelected: (value: string) => void;
};

export const DropdownMenu: FC<DropdownMenuProps> = ({
  disabled = false,
  label,
  values,
  selected,
  setSelected,
}) => {
  return (
    <Listbox value={selected} onChange={setSelected} disabled={disabled}>
      {({ open, disabled }) => (
        <div className="flex flex-col items-center">
          {label && (
            <Listbox.Label className="block text-sm px-3 font-medium text-semibold text-gray-700">
              {label}
            </Listbox.Label>
          )}
          <div className="relative mt-3">
            <Listbox.Button
              className={`relative min-w-[12.5rem] w-full h-10 cursor-default rounded-sm py-2 pl-3 pr-10 text-left shadow-lg transition border border-theme-olive hover:border-theme-dark-olive sm:text-sm ${
                disabled
                  ? "bg-slate-100 pointer-events-none"
                  : "bg-white cursor-pointer"
              }`}
            >
              <span className="block truncate">{selected}</span>
              <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                <ChevronDownIcon
                  className={`h-5 w-5 text-gray-400 transition ${
                    open ? "rotate-180" : "rotate-0"
                  }`}
                  aria-hidden="true"
                />
              </span>
            </Listbox.Button>

            <Transition
              show={open}
              as={Fragment}
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Listbox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-sm bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                {values
                  .filter((value) => value)
                  .map((value, index) => (
                    <Listbox.Option
                      key={`option-${index}`}
                      className={({ active }) =>
                        classNames(
                          active
                            ? "text-white bg-theme-olive"
                            : "text-gray-900",
                          "relative cursor-default select-none py-2 pl-3 pr-9"
                        )
                      }
                      value={value}
                    >
                      {({ selected, active }) => (
                        <>
                          <span
                            className={classNames(
                              selected ? "font-semibold" : "font-normal",
                              "block truncate"
                            )}
                          >
                            {value}
                          </span>

                          {selected && (
                            <span
                              className={classNames(
                                active ? "text-white" : "text-theme-dark-olive",
                                "absolute inset-y-0 right-0 flex items-center pr-4"
                              )}
                            >
                              <CheckIcon
                                className="h-4 w-4"
                                aria-hidden="true"
                              />
                            </span>
                          )}
                        </>
                      )}
                    </Listbox.Option>
                  ))}
              </Listbox.Options>
            </Transition>
          </div>
        </div>
      )}
    </Listbox>
  );
};

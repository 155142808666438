import create from "zustand";

export type TeamType = {
  id: number;
  name: string;
  chips: number;
  heads: number;
  hands: number;
  hearts: number;
  pawn: string;
  currentTileId: number;
  coordinates: { x: number; y: number };
};

interface TeamsState {
  teams: Array<TeamType>;
  setTeams: (value: Array<TeamType>) => void;
  updateTeam: (team: TeamType) => void;
  updateTeamProperty: (values: {
    id: number;
    key: keyof TeamType;
    value: unknown;
    add?: boolean;
  }) => void;
}

export const useTeamsStore = create<TeamsState>()((set) => ({
  teams: [],
  setTeams: (value) => set((state) => ({ teams: value })),
  updateTeam: (team) =>
    set(({ teams }) => {
      const index = teams.findIndex(({ id }) => id === team.id);

      if (index !== -1) {
        // replaces existing team in array with new team and sorts by id
        return {
          teams: teams.splice(index, 1, team).sort((a, b) => a.id - b.id),
        };
      }

      return { teams };
    }),
  updateTeamProperty: ({ id: teamId, key, value, add = false }) =>
    set(({ teams }) => {
      const index = teams.findIndex(({ id }) => id === teamId);

      if (index !== -1) {
        if (typeof value === "number" && add) {
          //@ts-ignore
          teams[index][key] += value;
        } else {
          //@ts-ignore
          teams[index][key] = value;
        }
      }

      return { teams: [...teams] };
    }),
}));

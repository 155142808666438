import { PawnBalloonIcon } from "./PawnBalloonIcon";
import { PawnBoatIcon } from "./PawnBoatIcon";
import { PawnBusIcon } from "./PawnBusIcon";
import { PawnCarIcon } from "./PawnCarIcon";
import { PawnDragonIcon } from "./PawnDragonIcon";
import { PawnFormulaIcon } from "./PawnFormulaIcon";
import { PawnHelicopterIcon } from "./PawnHelicopterIcon";
import { PawnHorseIcon } from "./PawnHorseIcon";
import { PawnMopedIcon } from "./PawnMopedIcon";
import { PawnParachuteIcon } from "./PawnParachuteIcon";
import { PawnPlaneIcon } from "./PawnPlaneIcon";
import { PawnRollerbladeIcon } from "./PawnRollerbladeIcon";
import { PawnScooterIcon } from "./PawnScooterIcon";
import { PawnSkateboardIcon } from "./PawnSkateboardIcon";
import { PawnSnowboardIcon } from "./PawnSnowboardIcon";
import { PawnTramIcon } from "./PawnTramIcon";

export { PawnBalloonIcon } from "./PawnBalloonIcon";
export { PawnBoatIcon } from "./PawnBoatIcon";
export { PawnBusIcon } from "./PawnBusIcon";
export { PawnCarIcon } from "./PawnCarIcon";
export { PawnDragonIcon } from "./PawnDragonIcon";
export { PawnFormulaIcon } from "./PawnFormulaIcon";
export { PawnHelicopterIcon } from "./PawnHelicopterIcon";
export { PawnHorseIcon } from "./PawnHorseIcon";
export { PawnMopedIcon } from "./PawnMopedIcon";
export { PawnParachuteIcon } from "./PawnParachuteIcon";
export { PawnPlaneIcon } from "./PawnPlaneIcon";
export { PawnRollerbladeIcon } from "./PawnRollerbladeIcon";
export { PawnScooterIcon } from "./PawnScooterIcon";
export { PawnSkateboardIcon } from "./PawnSkateboardIcon";
export { PawnSnowboardIcon } from "./PawnSnowboardIcon";
export { PawnTramIcon } from "./PawnTramIcon";

export const iconNames = [
  "PawnBalloonIcon",
  "PawnBoatIcon",
  "PawnBusIcon",
  "PawnCarIcon",
  "PawnDragonIcon",
  "PawnFormulaIcon",
  "PawnHelicopterIcon",
  "PawnHorseIcon",
  "PawnMopedIcon",
  "PawnParachuteIcon",
  "PawnPlaneIcon",
  "PawnRollerbladeIcon",
  "PawnScooterIcon",
  "PawnSkateboardIcon",
  "PawnSnowboardIcon",
  "PawnTramIcon",
];

export const renderIcon = (iconName: string, scaleUp = false) => {
  switch (iconName) {
    case "PawnBalloonIcon":
      return (
        <PawnBalloonIcon
          {...(scaleUp ? { width: "100%", height: "100%" } : {})}
        />
      );
    case "PawnBoatIcon":
      return (
        <PawnBoatIcon {...(scaleUp ? { width: "100%", height: "100%" } : {})} />
      );
    case "PawnBusIcon":
      return (
        <PawnBusIcon {...(scaleUp ? { width: "100%", height: "100%" } : {})} />
      );
    case "PawnCarIcon":
      return (
        <PawnCarIcon {...(scaleUp ? { width: "100%", height: "100%" } : {})} />
      );
    case "PawnDragonIcon":
      return (
        <PawnDragonIcon
          {...(scaleUp ? { width: "100%", height: "100%" } : {})}
        />
      );
    case "PawnFormulaIcon":
      return (
        <PawnFormulaIcon
          {...(scaleUp ? { width: "100%", height: "100%" } : {})}
        />
      );
    case "PawnHelicopterIcon":
      return (
        <PawnHelicopterIcon
          {...(scaleUp ? { width: "100%", height: "100%" } : {})}
        />
      );
    case "PawnHorseIcon":
      return (
        <PawnHorseIcon
          {...(scaleUp ? { width: "100%", height: "100%" } : {})}
        />
      );
    case "PawnMopedIcon":
      return (
        <PawnMopedIcon
          {...(scaleUp ? { width: "100%", height: "100%" } : {})}
        />
      );
    case "PawnParachuteIcon":
      return (
        <PawnParachuteIcon
          {...(scaleUp ? { width: "100%", height: "100%" } : {})}
        />
      );
    case "PawnPlaneIcon":
      return (
        <PawnPlaneIcon
          {...(scaleUp ? { width: "100%", height: "100%" } : {})}
        />
      );
    case "PawnRollerbladeIcon":
      return (
        <PawnRollerbladeIcon
          {...(scaleUp ? { width: "100%", height: "100%" } : {})}
        />
      );
    case "PawnScooterIcon":
      return (
        <PawnScooterIcon
          {...(scaleUp ? { width: "100%", height: "100%" } : {})}
        />
      );
    case "PawnSkateboardIcon":
      return (
        <PawnSkateboardIcon
          {...(scaleUp ? { width: "100%", height: "100%" } : {})}
        />
      );
    case "PawnSnowboardIcon":
      return (
        <PawnSnowboardIcon
          {...(scaleUp ? { width: "100%", height: "100%" } : {})}
        />
      );
    case "PawnTramIcon":
      return (
        <PawnTramIcon {...(scaleUp ? { width: "100%", height: "100%" } : {})} />
      );
    default:
      return <></>;
  }
};

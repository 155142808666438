import create from "zustand";

interface MultipleMapsState {
  multipleMaps: boolean;
  setMultipleMaps: (value: boolean) => void;
}

export const useMultipleMapsStore = create<MultipleMapsState>()((set) => ({
  multipleMaps: false,
  setMultipleMaps: (value) => set((state) => ({ multipleMaps: value })),
}));

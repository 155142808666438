import { FC, useState, useEffect, useCallback, useMemo } from "react";

import { Button, Modal } from "..";
import {
  ChevronDownIcon,
  HandThumbDownIcon,
  HandThumbUpIcon,
} from "@heroicons/react/24/outline";
import { ChipsIcon, HandsIcon, HeadsIcon, HeartsIcon } from "../../icons";

import { useTopicStore } from "../../store/topic";
import { TeamType, useTeamsStore } from "../../store/teams";

import { useTranslation } from "react-i18next";

import { points, images } from "../../constants";

export interface QuestionModalProps {
  answeringTeamId: number;
  show: boolean;
  setShow: (value: boolean) => void;
}

export const QuestionModal: FC<QuestionModalProps> = ({
  answeringTeamId,
  show,
  setShow,
}) => {
  const [visible, setVisible] = useState<boolean>(true);
  const { t } = useTranslation(["translation", "en"]);

  const topic = useTopicStore((state) => state.topic);
  const updateTeamProperty = useTeamsStore((state) => state.updateTeamProperty);

  const [currentQuestion, setCurrentQuestion] = useState<number>(0);
  const [questions, setQuestions] = useState<Array<number>>([
    0, 1, 2, 3, 4, 5, 6, 7, 8, 9,
  ]);
  const [usedQuestions, setUsedQuestions] = useState<Array<number>>([]);

  const questionPoints = useMemo(() => {
    if (topic && currentQuestion > -1) {
      //@ts-ignore
      return points[topic][currentQuestion];
    }
  }, [currentQuestion, topic]);

  const chooseQuestion = useCallback(
    (sequential = false) => {
      if (questions.length > 0) {
        setCurrentQuestion(
          sequential
            ? questions[0]
            : questions[Math.floor(Math.random() * questions.length)]
        );
      } else {
        setCurrentQuestion(-1);
      }
    },
    [questions]
  );

  const answerQuestion = useCallback(
    (success: boolean) => {
      const index = questions.indexOf(currentQuestion);

      setUsedQuestions(usedQuestions.concat([questions[index]]));

      if (index !== -1) {
        const qCopy = questions;
        qCopy.splice(index, 1);
        setQuestions(qCopy);
      }

      if (success && questionPoints) {
        ["chips", "heads", "hearts", "hands"].forEach((property) => {
          updateTeamProperty({
            id: answeringTeamId,
            key: property as keyof TeamType,
            value: questionPoints[property],
            add: true,
          });
        });
      }

      setShow(false);
    },
    [
      questions,
      currentQuestion,
      usedQuestions,
      questionPoints,
      setShow,
      updateTeamProperty,
      answeringTeamId,
    ]
  );

  useEffect(() => {
    if (show) {
      chooseQuestion(topic === "Debriefing" || topic === "Project Design");
    }
  }, [chooseQuestion, show, topic]);

  return (
    <Modal
      open={show}
      setOpen={setShow}
      maxWidth="max-w-xl"
      visible={visible}
      setVisible={setVisible}
    >
      <div className="space-y-16 text-lg">
        <div>
          <b>
            {currentQuestion !== -1
              ? t(`${topic}.${currentQuestion}`)
              : "No questions remaining! Click the center of the map to see if you won."}
          </b>
        </div>

        {topic && currentQuestion !== -1 && images[topic][currentQuestion] && (
          <div className="flex justify-center">
            <img src={images[topic][currentQuestion]} alt="Example" />
          </div>
        )}

        {currentQuestion !== -1 && (
          <div className="flex w-full items-center justify-between">
            <div className="border border-theme-orange rounded p-2 sm:p-4 flex items-center">
              <ChipsIcon className="w-6 h-6" />: {questionPoints?.chips}
            </div>
            <div className="border border-theme-orange rounded p-2 sm:p-4 flex items-center">
              <HeadsIcon className="w-6 h-6" />: {questionPoints?.heads}
            </div>
            <div className="border border-theme-orange rounded p-2 sm:p-4 flex items-center">
              <HeartsIcon className="w-6 h-6" />: {questionPoints?.hearts}
            </div>
            <div className="border border-theme-orange rounded p-2 sm:p-4 flex items-center">
              <HandsIcon className="w-6 h-6" />: {questionPoints?.hands}
            </div>
          </div>
        )}

        <div
          className={`flex w-full items-center ${
            currentQuestion !== -1 ? "justify-between" : "justify-center"
          }`}
        >
          <Button
            className="rounded-full shadow-md shadow-green-300 p-4 sm:p-6 border border-green-300 transition hover:scale-105"
            onClick={() => answerQuestion(true)}
          >
            <HandThumbUpIcon className="w-8 h-8" />
          </Button>

          <Button
            className="rounded-full shadow-md shadow-blue-300 p-4 sm:p-6 border border-blue-300 transition hover:scale-105"
            onClick={() => setVisible(false)}
          >
            <ChevronDownIcon className="w-8 h-8" />
          </Button>

          {currentQuestion !== -1 && (
            <Button
              className="rounded-full shadow-md shadow-red-300 p-4 sm:p-6 border border-red-300 transition hover:scale-105"
              onClick={() => answerQuestion(false)}
            >
              <HandThumbDownIcon className="w-8 h-8" />
            </Button>
          )}
        </div>
      </div>
    </Modal>
  );
};

import { FC, useEffect, useState } from "react";
import {
  MapOverlay,
  TeamComponent,
  TeamsContainer,
  QuestionModal,
} from "../components";
import { useNavigate } from "react-router-dom";

import { useNoOfTeamStore } from "../store/noOfTeams";
import { TeamType, useTeamsStore } from "../store/teams";
import { useMapStore } from "../store/map";
import { iconNames } from "../icons/pawns";

export const Map: FC = () => {
  const noOfTeams = useNoOfTeamStore((state) => state.noOfTeams);
  const map = useMapStore((state) => state.map);
  const teams = useTeamsStore((state) => state.teams);
  const setTeams = useTeamsStore((state) => state.setTeams);

  const [answeringTeamId, setAnsweringTeamId] = useState<number>(1);
  const [questionModal, setQuestionModal] = useState<boolean>(false);

  const navigate = useNavigate();

  useEffect(() => {
    if (!map && !noOfTeams) {
      navigate("/", { replace: true });
    }

    const tempTeams = [];
    for (let i = 1; i <= noOfTeams; i++) {
      tempTeams.push({
        id: i,
        name: `Team ${i}`,
        chips: 5,
        heads: 0,
        hands: 0,
        hearts: 0,
        pawn: iconNames[i],
        currentTileId: -1,
        coordinates: { x: 200 + i * 40, y: -40 },
      });
    }
    setTeams(tempTeams);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <div className="w-full h-full overflow-auto px-15 py-5">
        <div className="flex flex-col sm:flex-row justify-between items-center">
          <TeamsContainer left>
            {teams
              .filter((t: any, i: number) => i % 2 === 0)
              .map((team: TeamType) => (
                <TeamComponent key={team.id} team={team} />
              ))}
          </TeamsContainer>

          <MapOverlay
            teams={teams}
            onPawnMoved={(toggleQuestionModal, teamId) => {
              setQuestionModal(toggleQuestionModal);
              setAnsweringTeamId(teamId);
            }}
          />

          <TeamsContainer>
            {teams
              .filter((t: any, i: number) => i % 2 !== 0)
              .map((team: any) => (
                <TeamComponent key={team.id} team={team} />
              ))}
          </TeamsContainer>
        </div>
      </div>

      <QuestionModal
        answeringTeamId={answeringTeamId}
        show={questionModal}
        setShow={setQuestionModal}
      />
    </>
  );
};

import { FC } from "react";

export const PawnRollerbladeIcon: FC<{ height?: string; width?: string }> = ({
  height = "11",
  width = "10",
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 10 11"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.39391 7.94948C7.39391 8.34937 8.13723 8.96296 8.69696 8.96296C9.25669 8.96296 10 8.34937 10 7.94948C10 7.54959 9.25669 7.51514 8.69696 7.51514C8.13723 7.51514 7.39391 7.54959 7.39391 7.94948ZM1.89217 7.94948C1.89217 8.34937 2.63549 8.96296 3.19521 8.96296C3.75494 8.96296 4.49826 8.34937 4.49826 7.94948C4.49826 7.54959 3.75494 7.51514 3.19521 7.51514C2.63549 7.51514 1.89217 7.54959 1.89217 7.94948ZM1.89217 7.8047C1.89217 8.09427 1.89217 8.38383 1.31304 8.6734C0.73391 8.96296 0.73391 7.8047 0.73391 7.8047H1.89217Z"
        fill="#292F33"
      />
      <path
        d="M0.216163 8.32436C-0.169538 8.71007 0.00506997 8.8117 0.390771 9.19769C0.776472 9.58339 0.878109 9.758 1.2641 9.3723C1.6498 8.9866 1.82441 8.53575 1.43871 8.14976C1.05301 7.76377 0.602154 7.93837 0.216163 8.32436Z"
        fill="#3E4347"
      />
      <path
        d="M0.647081 6.95723C0.647081 6.95723 0.47479 6.93609 0.47479 7.22623C0.47479 7.51696 0.608569 7.51464 0.899292 7.51464H4.7453C5.91427 7.51464 7.21037 6.67809 7.76807 6.67809L7.93312 7.51493H9.71974C9.71974 7.1188 9.96384 6.62191 9.96529 6.3587C9.96848 5.77754 9.77794 5.13354 9.77794 5.13354L5.2587 6.67838L0.647081 6.95723Z"
        fill="#88863B"
      />
      <path
        d="M2.62405 4.75775C3.26631 4.46297 4.36811 3.50509 4.42226 3.44428C5.77482 1.14079 4.97996 0.265431 4.97996 0.265431C4.97996 0.265431 5.35437 -0.008208 5.53766 0.000189394L5.81651 0.265431L6.09536 0.0372538C7.03877 0.117753 9.441 1.38084 9.441 1.38084C9.441 1.38084 9.16215 2.21739 9.16215 2.7748C9.16215 3.33222 9.36369 4.00198 9.36369 4.00198C9.36369 4.00198 9.76416 4.67899 9.83597 4.99403C9.91589 5.34528 9.77458 5.5833 9.53366 5.6415C8.68726 5.84536 7.91847 5.8219 6.19642 6.58375C5.52839 6.8791 5.08102 6.95729 4.42226 6.95729H0.647192C0.647192 6.95729 0.29566 6.51773 0.597097 5.72374C0.704236 5.44141 1.96529 5.06005 2.62405 4.75775Z"
        fill="#68964D"
      />
      <path
        d="M2.68601 4.72676C3.33464 4.40274 4.36983 3.50335 4.42196 3.44428C5.77451 1.14079 4.97966 0.265431 4.97966 0.265431C4.97966 0.265431 5.35407 -0.008208 5.53736 0.000189394L5.81621 0.265431L6.09506 0.0372538C6.10143 0.0378329 6.10983 0.0395703 6.1162 0.040439C6.13271 0.185222 6.15819 0.422086 6.19728 0.802864C6.34785 2.27328 5.57877 3.3215 4.80968 4.14966C4.0406 4.97782 3.24893 5.16053 2.87018 5.24827C2.49142 5.33601 2.00901 5.02589 2.00901 5.02589L2.68601 4.72676Z"
        fill="#BCBB85"
      />
      <path
        d="M0.444345 8.09427H9.71043C9.8697 8.09427 10 7.96396 10 7.8047C10 7.64544 9.8697 7.51514 9.71043 7.51514H0.444345C0.285085 7.51514 0.15478 7.64544 0.15478 7.8047C0.15478 7.96396 0.285085 8.09427 0.444345 8.09427Z"
        fill="#BCBB85"
      />
      <path
        d="M8.69696 10.4105C7.9773 10.4105 7.39391 9.82714 7.39391 9.10749C7.39391 8.38784 7.9773 7.80444 8.69696 7.80444C9.41661 7.80444 10 8.38784 10 9.10749C10 9.82714 9.41661 10.4105 8.69696 10.4105Z"
        fill="#3E4347"
      />
      <path
        d="M8.69687 9.7591C8.33704 9.7591 8.04534 9.4674 8.04534 9.10758C8.04534 8.74775 8.33704 8.45605 8.69687 8.45605C9.05669 8.45605 9.34839 8.74775 9.34839 9.10758C9.34839 9.4674 9.05669 9.7591 8.69687 9.7591Z"
        fill="#BCBB85"
      />
      <path
        d="M3.19525 10.4105C2.4756 10.4105 1.8922 9.82714 1.8922 9.10749C1.8922 8.38784 2.4756 7.80444 3.19525 7.80444C3.9149 7.80444 4.49829 8.38784 4.49829 9.10749C4.49829 9.82714 3.9149 10.4105 3.19525 10.4105Z"
        fill="#3E4347"
      />
      <path
        d="M3.19516 9.7591C2.83533 9.7591 2.54364 9.4674 2.54364 9.10758C2.54364 8.74775 2.83533 8.45605 3.19516 8.45605C3.55498 8.45605 3.84668 8.74775 3.84668 9.10758C3.84668 9.4674 3.55498 9.7591 3.19516 9.7591Z"
        fill="#BCBB85"
      />
      <path
        d="M5.8164 0.265775C6.36396 0.815949 6.13694 2.76443 5.28794 3.5535C6.98566 2.0828 6.09525 0.0375977 6.09525 0.0375977L5.8164 0.265775Z"
        fill="#88863B"
      />
      <path
        d="M5.72169 3.89931C5.76321 3.83477 5.7775 3.75643 5.76144 3.68139C5.74538 3.60636 5.70028 3.54073 5.63598 3.49884L4.83331 2.9727C4.76877 2.93118 4.69043 2.91689 4.6154 2.93295C4.54036 2.94901 4.47473 2.99412 4.43284 3.05841C4.39132 3.12294 4.37704 3.20128 4.3931 3.27632C4.40916 3.35136 4.45426 3.41699 4.51855 3.45888L5.32123 3.98502C5.38576 4.02653 5.4641 4.04082 5.53914 4.02476C5.61417 4.0087 5.6798 3.9636 5.72169 3.89931ZM4.38506 4.98662C4.44688 4.94111 4.48814 4.87295 4.49981 4.79708C4.51148 4.7212 4.4926 4.6438 4.44732 4.58181L3.98546 3.95172C3.93995 3.8899 3.87179 3.84864 3.79591 3.83697C3.72004 3.8253 3.64263 3.84417 3.58065 3.88946C3.45208 3.98357 3.42399 4.16571 3.51839 4.29427L3.98025 4.92437C4.07436 5.05264 4.25649 5.08073 4.38506 4.98662ZM3.00181 5.53767C3.07414 5.51196 3.13336 5.45867 3.16651 5.38943C3.19966 5.32019 3.20406 5.24065 3.17873 5.16818L3.00123 4.66463C2.97552 4.59229 2.92223 4.53308 2.85299 4.49992C2.78376 4.46677 2.70421 4.46238 2.63174 4.4877C2.48146 4.54069 2.40183 4.7069 2.45482 4.85719L2.63232 5.36074C2.65803 5.43308 2.71132 5.49229 2.78056 5.52544C2.84979 5.5586 2.92934 5.56299 3.00181 5.53767ZM6.67378 0.695555C6.66701 0.619133 6.63024 0.548506 6.57153 0.499124C6.51281 0.449742 6.43693 0.425625 6.36048 0.43205L5.20656 0.53195C5.04788 0.54556 4.92915 0.686578 4.94305 0.84526C4.94983 0.921681 4.9866 0.992309 5.04531 1.04169C5.10403 1.09107 5.17991 1.11519 5.25636 1.10876L6.41028 1.00886C6.4867 1.00209 6.55733 0.965323 6.60671 0.906607C6.65609 0.847891 6.68021 0.772006 6.67378 0.695555ZM6.50468 2.29917C6.51896 2.22378 6.50282 2.1458 6.4598 2.08227C6.41677 2.01874 6.35036 1.97481 6.27505 1.96009L5.197 1.76116C5.12161 1.74688 5.04364 1.76301 4.9801 1.80604C4.91657 1.84906 4.87265 1.91548 4.85792 1.99078C4.84364 2.06617 4.85978 2.14415 4.9028 2.20768C4.94583 2.27121 5.01224 2.31514 5.08755 2.32986L6.1656 2.52879C6.24099 2.54307 6.31896 2.52694 6.3825 2.48391C6.44603 2.44089 6.48995 2.37447 6.50468 2.29917Z"
        fill="#848404"
      />
    </svg>
  );
};

import { FC } from "react";

export const PawnMopedIcon: FC<{ height?: string; width?: string }> = ({
  height = "10",
  width = "12",
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 12 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.98639 9.94335C11.1212 9.82881 11.7508 8.91081 11.5364 8.01076C11.3689 7.30986 10.6787 6.60897 9.46059 6.73034C8.1691 6.85855 7.76569 7.9381 7.93117 8.66293C8.12504 9.50999 8.92892 10.0502 9.98639 9.94335Z"
        fill="#4D453C"
      />
      <path
        d="M9.54313 7.51583C9.02418 7.60472 8.64134 7.99193 8.74023 8.50649C8.84402 9.04754 9.38843 9.27234 9.92891 9.18601C10.5037 9.0937 10.7876 8.68427 10.6956 8.18595C10.6133 7.73636 10.0836 7.42352 9.54313 7.51583Z"
        fill="white"
      />
      <path
        d="M9.83473 9.04587C10.3272 8.99715 10.6014 8.60653 10.5084 8.22275C10.4359 7.92444 10.1353 7.62613 9.60659 7.67742C9.04554 7.73212 8.87028 8.19198 8.94273 8.50054C9.02596 8.8621 9.37552 9.09203 9.83473 9.04587Z"
        fill="#BCBB85"
      />
      <path
        d="M2.04381 9.98699C3.15318 9.87587 3.76808 8.98693 3.55855 8.11594C3.39503 7.43727 2.71942 6.75945 1.52976 6.87656C0.267639 7.00049 -0.127935 8.04585 0.0346027 8.7476C0.224557 9.56816 1.01081 10.0904 2.04381 9.98699Z"
        fill="#4D453C"
      />
      <path
        d="M1.62366 7.55616C1.08219 7.64847 0.683676 8.05191 0.786486 8.58784C0.895171 9.15198 1.44153 9.39643 2.00552 9.30583C2.60378 9.2101 2.90242 8.78016 2.80646 8.26133C2.7203 7.79292 2.18568 7.46043 1.62366 7.55616Z"
        fill="white"
      />
      <path
        d="M1.90767 9.09551C2.37766 9.04936 2.63811 8.67925 2.54901 8.31598C2.47949 8.03306 2.19358 7.75099 1.68932 7.79971C1.15471 7.851 0.987271 8.28692 1.05581 8.57924C1.13708 8.92029 1.46999 9.13825 1.90767 9.09551Z"
        fill="#BCBB85"
      />
      <path
        d="M2.46867 6.87646C2.46867 6.87646 1.60604 8.09192 1.50127 8.28595C1.3965 8.47998 1.39063 8.66888 1.60604 8.77828C1.83418 8.89453 2.04567 8.74751 2.17101 8.55519C2.26696 8.40818 3.37437 6.94314 3.37437 6.94314L2.46867 6.87646Z"
        fill="#88863B"
      />
      <path
        d="M2.2339 5.9303C2.2339 5.9303 2.13011 5.9303 2.01163 5.94568C1.89315 5.96192 1.85986 5.97047 1.85986 5.97047L2.02436 6.86112L2.86349 7.28337C2.86349 7.28337 3.26592 7.49705 3.59882 7.77399C3.68303 7.84408 3.81228 7.7928 3.80347 7.76117C3.79465 7.72955 2.92811 6.04141 2.92811 6.04141L2.2339 5.9303Z"
        fill="#88863B"
      />
      <path
        d="M0.474164 7.09958C0.537809 7.18335 1.02542 7.01838 1.81168 7.04573C2.38741 7.06625 2.87601 7.29276 2.87601 7.29276C2.87601 7.29276 2.54995 6.70469 2.33454 6.43373C2.06527 6.0944 1.86161 5.97046 1.86161 5.97046C1.86161 5.97046 1.46016 6.02089 0.981361 6.35937C0.625932 6.61067 0.39877 7.00043 0.474164 7.09958Z"
        fill="#BCBB85"
      />
      <path
        d="M0.967835 6.66539C1.04127 6.75172 1.28802 6.56282 1.5142 6.48418C1.74038 6.40554 1.91956 6.40896 1.97146 6.33203C2.02335 6.25425 2.02825 6.10211 1.79717 6.10553C1.43391 6.11322 0.860129 6.53803 0.967835 6.66539Z"
        fill="#88863B"
      />
      <path
        d="M4.19121 2.60449C4.17946 2.63954 3.89551 3.20111 3.89551 3.20111L4.29108 3.83106L4.81199 3.44471L4.999 2.98144L4.19121 2.60449Z"
        fill="#BCBB85"
      />
      <path
        d="M4.57788 2.90195C4.57788 2.90195 4.93135 3.08059 4.99891 3.0464C5.06647 3.01221 5.25741 2.54466 5.34064 2.52928C5.50024 2.49936 5.74111 2.43611 5.79594 2.18139C5.88112 1.78906 5.55115 1.79334 5.55115 1.79334L4.58278 2.01728L4.57788 2.90195Z"
        fill="#88863B"
      />
      <path
        d="M4.63478 1.19758C4.52708 1.1574 3.31392 1.21724 3.13767 1.25228C2.96143 1.28733 2.96143 1.51554 2.96143 1.51554L3.24636 2.44979C3.24636 2.44979 3.34917 2.48996 3.50877 2.48996C3.62235 2.48996 3.70851 2.51988 3.77607 2.54979C3.84461 2.57971 4.57897 2.9028 4.57897 2.9028L5.11457 2.06258L5.74122 1.89334C5.74122 1.89334 5.68443 1.78394 5.46216 1.72923C5.2399 1.67453 5.0519 1.60529 4.93832 1.4805C4.82278 1.35656 4.74347 1.2369 4.63478 1.19758Z"
        fill="#BCBB85"
      />
      <path
        d="M4.36724 1.3958C4.39172 1.47786 4.24191 1.59496 4.05391 1.69411C3.86592 1.79326 3.60938 1.88814 3.53007 1.75395C3.45076 1.61975 3.34501 1.38298 3.50167 1.35136C3.67204 1.31631 4.32709 1.26161 4.36724 1.3958Z"
        fill="#68964D"
      />
      <path
        d="M3.01205 1.33171C2.90924 1.34196 2.72124 1.57275 2.84168 1.9779C2.96113 2.38049 3.18731 2.49588 3.343 2.45485C3.53099 2.40527 3.48498 2.13688 3.38902 1.7984C3.31265 1.5377 3.12563 1.3206 3.01205 1.33171Z"
        fill="#88863B"
      />
      <path
        d="M6.78183 1.8489C6.78183 1.8489 5.43844 1.85403 5.30723 1.85916C5.17603 1.86429 5.0507 1.97882 5.0742 2.11302C5.09672 2.24721 5.19365 2.32671 5.33073 2.32671C5.46781 2.32671 6.72601 2.31645 6.72601 2.31645C6.72601 2.31645 6.87387 2.25662 6.87387 2.04806C6.87289 1.87369 6.78183 1.8489 6.78183 1.8489ZM5.1868 1.11296L5.17505 1.65487C5.17505 1.65487 5.14665 1.81984 5.00468 1.82411C4.81081 1.82924 4.80493 1.64547 4.80493 1.64547C4.80493 1.64547 4.81081 0.806105 4.81081 0.760803C4.81081 0.636864 4.92439 0.41292 5.09574 0.238551C5.26709 0.0641816 5.41494 -0.040098 5.55104 0.014606C5.68812 0.06931 5.71064 0.34283 5.70477 0.595836C5.69889 0.853971 5.67539 1.15997 5.55692 1.19758C5.39829 1.24716 5.1868 1.11296 5.1868 1.11296Z"
        fill="#68964D"
      />
      <path
        d="M10.5586 5.22003C10.5586 5.22003 11.0824 5.10464 11.1686 5.12687C11.2548 5.14909 11.3429 5.29525 11.3977 5.49099C11.4486 5.67305 11.5005 5.90298 11.4741 5.93033C11.4232 5.98333 11.2146 6.01495 11.2146 6.01495C11.2146 6.01495 10.5792 5.54056 10.5694 5.51321C10.5596 5.48586 10.5586 5.22003 10.5586 5.22003Z"
        fill="#BCBB85"
      />
      <path
        d="M4.10525 3.0498C4.10525 3.0498 4.66435 3.27888 4.74268 3.31649C4.82101 3.35495 4.88269 3.41564 4.82101 3.54556C4.7603 3.67548 4.3755 4.71999 4.31479 5.07043C4.25408 5.42088 4.28835 5.80979 4.28835 5.80979L3.10065 6.03887L2.89111 5.61918L4.10525 3.0498Z"
        fill="#88863B"
      />
      <path
        d="M3.19629 5.78674L4.28804 5.80982C4.28804 5.80982 4.31349 6.11924 4.48876 6.48849C4.61801 6.75945 4.86965 7.19452 5.06548 7.30392C5.14675 7.35008 5.42384 7.41846 5.71171 7.41846C5.96531 7.41846 6.16506 7.35521 6.30605 7.27914C6.46663 7.19195 6.54105 7.10562 6.54105 7.10562L7.65042 6.96801L7.52803 8.25612L4.82069 8.26381L3.36176 6.99023L3.19629 5.78674Z"
        fill="#BCBB85"
      />
      <path
        d="M7.05102 5.00294C6.88457 5.02687 6.49683 5.3098 6.49683 5.3098L6.56928 5.59614L9.63694 6.21669L11.6383 6.49363C11.6383 6.49363 11.3221 5.90556 10.904 5.46451C10.5593 5.10038 10.3145 4.96448 10.3145 4.96448L7.05102 5.00294Z"
        fill="#88863B"
      />
      <path
        d="M3.90448 2.96094C3.90448 2.96094 4.19235 3.08146 4.26383 3.11736C4.37154 3.17206 4.28831 3.41054 4.19627 3.54644C4.10423 3.68235 3.71257 4.17383 3.50499 4.72942C3.34148 5.16619 3.37868 5.84401 3.43352 6.10727C3.48247 6.34318 4.00729 7.23041 4.24718 7.5518C4.51155 7.90652 4.75732 7.99029 4.97763 8.02875C5.33404 8.09115 6.53936 8.04499 6.91438 7.98089C7.28939 7.91678 7.42549 7.71762 7.1337 7.48684C6.84094 7.2552 6.54328 7.10648 6.54328 7.10648C6.54328 7.10648 6.79688 6.79962 6.75869 6.50559C6.73128 6.29019 6.56874 5.59613 6.56874 5.59613L8.19216 5.58075L9.58059 5.52519L10.7898 5.78161C10.7898 5.78161 11.1854 5.91495 11.5222 6.32267C11.7788 6.63294 11.9012 6.95262 11.9286 7.04835C11.956 7.14408 12.0941 7.49197 11.8933 7.53214C11.6926 7.57231 8.84623 7.78002 8.6083 7.81164C8.37036 7.84327 8.06096 7.80395 7.94542 8.06892C7.82694 8.34244 7.86317 8.5869 7.28743 8.60314C6.71169 8.61938 4.89636 8.79204 4.41756 8.4869C4.01904 8.23389 3.48737 7.74925 2.94884 6.94407C2.41031 6.1389 2.16748 5.93205 2.16748 5.93205L2.2184 5.45339L3.08592 3.77893L3.83496 3.02162L3.90448 2.96094Z"
        fill="#68964D"
      />
      <path
        d="M3.69862 2.88314C3.64379 2.8669 2.97797 3.44813 2.601 4.11313C2.0987 4.99865 2.16528 5.93375 2.16528 5.93375C2.16528 5.93375 2.42671 5.78759 2.52756 5.61236C2.62841 5.43714 2.90257 4.68752 2.90257 4.68752C2.90257 4.68752 3.15813 4.20117 3.56056 3.65071C3.75933 3.37805 4.10203 3.06179 4.05209 3.02674C3.99824 2.98828 3.69862 2.88314 3.69862 2.88314ZM7.71605 7.5176C7.81984 7.46631 7.39881 6.70815 7.87174 6.1859C8.28298 5.73117 8.93117 5.67561 9.60776 5.69955C10.1669 5.71921 10.9414 5.85084 10.9414 5.85084C10.9414 5.85084 9.89073 5.09353 8.23696 5.36448C6.97778 5.57133 6.967 6.44061 7.10408 6.81585C7.25096 7.21587 7.65241 7.54923 7.71605 7.5176Z"
        fill="#68964D"
      />
      <path
        d="M6.21783 5.15758C6.22664 5.26101 6.23839 5.34306 6.45576 5.34135C6.67215 5.33964 7.54261 5.30973 8.57463 5.28579C9.60665 5.26186 9.96305 5.23793 10.1462 5.18237C10.3293 5.12681 10.4017 5.05501 10.4203 4.87893C10.4389 4.70371 10.4203 4.31309 10.3743 4.17718C10.3283 4.04128 10.2284 4.00196 9.99047 3.99341C9.77016 3.98572 9.10434 3.98572 9.00349 3.98572C8.79298 3.98572 8.08114 4.40027 7.98029 4.41651C7.87943 4.43275 7.53282 4.44814 7.13039 4.44814C6.8014 4.44814 6.50961 4.4319 6.43617 4.4242C6.36274 4.41651 6.19824 4.46267 6.19824 4.62336C6.2002 4.82252 6.21097 5.07381 6.21783 5.15758Z"
        fill="#BCBB85"
      />
      <path
        d="M2.96519 4.68757C2.82615 4.62859 2.5657 4.78159 2.42666 5.20469C2.29644 5.60044 2.46681 5.80473 2.58822 5.78507C2.70768 5.76541 2.70474 5.42009 2.82224 5.18931C2.93876 4.95853 3.09052 4.74056 2.96519 4.68757Z"
        fill="white"
      />
    </svg>
  );
};

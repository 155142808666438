import { FC } from "react";

export const PawnBusIcon: FC<{ height?: string; width?: string }> = ({
  height = "8",
  width = "12",
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 12 8"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.9321 1.40095C11.9321 0.678468 11.5487 0 9.81827 0H2.17279C0.441397 0 0.0579972 0.678468 0.0579972 1.40095V2.8909C0.0579972 2.89473 0.059872 2.89664 0.059872 2.90047C0.0523728 3.00286 0.0495605 3.10621 0.0495605 3.20956V6.62391C0.0495605 6.79712 0.185485 6.93587 0.355155 6.93587H11.6265C11.7953 6.93587 11.9321 6.79712 11.9321 6.62391V3.20956C11.9321 3.1177 11.9293 3.02679 11.9237 2.93588C11.9284 2.92153 11.9321 2.90717 11.9321 2.88995V1.40095Z"
        fill="#68964D"
      />
      <path d="M0 1.5H12V4.25H0V1.5Z" fill="#BCBB85" />
      <path
        d="M3.64735 6.85456C3.64735 7.48805 3.14397 8.00001 2.52434 8.00001C1.90378 8.00001 1.40039 7.48709 1.40039 6.85456C1.40039 6.22011 1.90378 5.70911 2.52434 5.70911C3.14397 5.70911 3.64735 6.22107 3.64735 6.85456ZM10.3226 6.85456C10.3226 7.48805 9.81924 8.00001 9.19962 8.00001C8.57905 8.00001 8.07566 7.48709 8.07566 6.85456C8.07566 6.22011 8.57905 5.70911 9.19962 5.70911C9.82018 5.70911 10.3226 6.22107 10.3226 6.85456Z"
        fill="#88863B"
      />
      <path
        d="M3.30325 3.62684C3.30325 3.79431 3.17108 3.92923 3.00609 3.92923H2.04057C1.87652 3.92923 1.74341 3.79431 1.74341 3.62684V2.02015C1.74341 1.85268 1.87652 1.7168 2.04057 1.7168H3.00609C3.17014 1.7168 3.30325 1.85268 3.30325 2.02015V3.62684ZM5.97017 2.02015C5.97017 1.85268 5.83706 1.7168 5.67208 1.7168H4.70655C4.5425 1.7168 4.41033 1.85268 4.41033 2.02015V3.62684C4.41033 3.79431 4.5425 3.92923 4.70655 3.92923H5.67208C5.83613 3.92923 5.97017 3.79431 5.97017 3.62684V2.02015ZM8.1309 2.02015C8.13065 1.9396 8.09913 1.86245 8.04326 1.80559C7.98738 1.74873 7.9117 1.7168 7.8328 1.7168H6.86727C6.70323 1.7168 6.57011 1.85268 6.57011 2.02015V3.62684C6.57011 3.79431 6.70323 3.92923 6.86727 3.92923H7.83374C7.99779 3.92923 8.13183 3.79431 8.13183 3.62684V2.02015H8.1309ZM10.2232 2.02015C10.2232 1.85268 10.091 1.7168 9.92509 1.7168H8.95956C8.79552 1.7168 8.66241 1.85268 8.66241 2.02015V3.62684C8.66241 3.79431 8.79552 3.92923 8.95956 3.92923H9.92416C10.0901 3.92923 10.2223 3.79431 10.2223 3.62684V2.02015H10.2232Z"
        fill="white"
      />
    </svg>
  );
};

import { FC, useCallback, useMemo } from "react";
import { useDrop } from "react-dnd";
import { useTeamsStore } from "../../store/teams";
import { toast } from "react-toastify";
import { searchableCoordinates } from "../../constants";
import { findShortestPath } from "../../utils/findShortestPath";

export type PointDescriptions =
  | "start-hex"
  | "regular-point"
  | "question-point";

export interface IGamePoint {
  /**
   * X coordinate
   */
  x: number;
  /**
   * Y coordinate
   */
  y: number;
  /**
   * Game point description, used to differentiate them
   */
  desc: PointDescriptions;
  /**
   * Unique numeric id
   */
  id: number;
  /**
   * Array of point IDs connected to this point
   */
  connects: { [key: number]: number };
}

export interface GamePointProps extends IGamePoint {
  /**
   * Function to call when a pawn is moved to a game point
   */
  onPawnMoved: (toggleQuestionModal: boolean, teamId: number) => void;
}

const ableToDropBg =
  "bg-gradient-radial from-green-500 via-transparent to-transparent";
const hoverDropBg =
  "bg-gradient-radial from-yellow-500 via-transparent to-transparent";
const unableToDropBg =
  "bg-gradient-radial from-red-500 via-transparent to-transparent";

export const GamePoint: FC<GamePointProps> = ({
  x,
  y,
  desc,
  id,
  connects,
  onPawnMoved,
}) => {
  const teams = useTeamsStore((state) => state.teams);
  const updateTeamProperty = useTeamsStore((state) => state.updateTeamProperty);

  const movePawn = useCallback(
    (teamId: number) => {
      const movedTeam = teams.find((team) => team.id === teamId)!;

      // if team is not initialized (moved onto a starting hex)
      if (desc !== "start-hex" && movedTeam.currentTileId < 1) {
        toast("You have to start on a hex!", { type: "warning" });
        return;
      } else if (desc === "start-hex" && movedTeam.currentTileId < 1) {
        updateTeamProperty({
          id: teamId,
          key: "coordinates",
          value: desc === "start-hex" ? { x: x + 32, y: y + 28 } : { x, y },
        });
        updateTeamProperty({
          id: teamId,
          key: "currentTileId",
          value: id,
        });
        return;
      }

      if (movedTeam!.currentTileId > 0) {
        const distance = findShortestPath(
          searchableCoordinates,
          searchableCoordinates[movedTeam!.currentTileId],
          searchableCoordinates[id]
        );

        if (distance > movedTeam.chips) {
          toast(
            `${movedTeam.name} needs more chips for this move! Need: ${distance} Have: ${movedTeam.chips}`,
            { type: "warning" }
          );
        } else {
          updateTeamProperty({
            id: teamId,
            key: "chips",
            value: -distance,
            add: true,
          });
          updateTeamProperty({
            id: teamId,
            key: "coordinates",
            value: desc === "start-hex" ? { x: x + 32, y: y + 28 } : { x, y },
          });
          updateTeamProperty({
            id: teamId,
            key: "currentTileId",
            value: id,
          });
          onPawnMoved(desc === "question-point", teamId);
        }
      }
    },
    [desc, id, onPawnMoved, teams, updateTeamProperty, x, y]
  );

  const [{ canDrop, isOver }, drop] = useDrop(
    () => ({
      accept: "pawn",
      drop: ({ teamId }: { teamId: number }) => movePawn(teamId),
      collect: (monitor) => ({
        isOver: monitor.isOver(),
        canDrop: monitor.canDrop(),
      }),
    }),
    [id, x, y, teams]
  );

  const size = useMemo(() => {
    return desc === "start-hex" ? "w-20 h-18" : "w-5 h-5";
  }, [desc]);

  return (
    <div
      ref={drop}
      className={`absolute rounded-full ${size} ${
        isOver ? hoverDropBg : "transparent"
      }`}
      style={{ left: x + "px", top: y + "px" }}
    >
      {/* {id.toString()} */}
    </div>
  );
};

import create from "zustand";

interface NoOfTeamState {
  noOfTeams: number;
  setNoOfTeams: (value: number) => void;
}

export const useNoOfTeamStore = create<NoOfTeamState>()((set) => ({
  noOfTeams: 0,
  setNoOfTeams: (value) => set(() => ({ noOfTeams: value })),
}));

import { FC } from "react";

export const PawnPlaneIcon: FC<{ height?: string; width?: string }> = ({
  height = "9",
  width = "15",
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 15 9"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.78702 2.91088L5.06437 2.26634C4.90031 2.24291 4.72452 2.25463 4.56046 2.28978C4.30265 2.34838 4.25578 2.69994 4.49015 2.82884L6.82218 4.01244L9.78702 2.91088Z"
        fill="#757F3F"
      />
      <path
        d="M8.46289 5.95776C8.68941 5.95776 8.87305 5.77413 8.87305 5.54761C8.87305 5.32108 8.68941 5.13745 8.46289 5.13745C8.23637 5.13745 8.05273 5.32108 8.05273 5.54761C8.05273 5.77413 8.23637 5.95776 8.46289 5.95776Z"
        fill="#BCBB85"
      />
      <path
        d="M12.8223 3.28589C13.0488 3.28589 13.2324 3.10226 13.2324 2.87573C13.2324 2.64921 13.0488 2.46558 12.8223 2.46558C12.5957 2.46558 12.4121 2.64921 12.4121 2.87573C12.4121 3.10226 12.5957 3.28589 12.8223 3.28589Z"
        fill="#BCBB85"
      />
      <path
        d="M13.1505 0.813187C13.1505 0.813187 13.1739 1.29366 12.3301 1.53975C12.2012 1.58662 12.3536 2.04366 12.4708 2.04366C12.6114 2.04366 14.2051 1.49287 14.1817 0.942094C13.8301 0.78975 13.1505 0.813187 13.1505 0.813187Z"
        fill="#0F6DA0"
      />
      <path
        d="M1.37303 5.4539C1.27928 5.37187 1.30272 5.20781 1.41991 5.14922C2.94335 4.43437 11.2637 0.567185 12.0957 0.168748C13.0098 -0.264846 13.6894 0.250779 13.9238 0.449997C14.1582 0.649216 14.4277 0.508591 14.5918 0.754685C14.7558 0.98906 14.709 1.64531 12.8691 2.48906C10.8887 3.40312 6.79881 5.27812 5.96678 5.62969C5.16991 5.96953 2.70897 6.59062 1.37303 5.4539Z"
        fill="#68964D"
      />
      <path
        d="M10.0567 3.74292C10.0919 3.70776 10.1036 3.64917 10.0802 3.60229C9.99815 3.44995 9.75205 3.13354 9.17783 3.13354C8.58018 3.13354 7.04502 4.04761 6.36533 4.43433C6.25987 4.49292 6.25987 4.63354 6.36533 4.70386C6.51768 4.78589 6.56455 4.98511 6.48252 5.13745L5.0294 7.6687C4.94737 7.82105 5.06455 8.00855 5.24033 8.00855H5.39268C5.7208 8.00855 6.03721 7.86792 6.25987 7.63354L10.0567 3.74292ZM2.74424 5.28979C2.47471 5.43042 2.14659 5.59448 1.98252 5.68823C1.94737 5.71167 1.91221 5.74683 1.88877 5.7937L1.67784 6.57886C1.63096 6.78979 1.90049 6.9187 2.04112 6.75464L3.02549 5.46558C3.13096 5.31323 2.97862 5.16089 2.74424 5.28979ZM3.22471 4.38745C3.18955 4.22339 2.46299 4.28198 1.87705 4.02417C1.10362 3.68433 0.670023 3.44995 0.295023 3.27417C0.0958044 3.18042 -0.0916956 3.36792 0.0489294 3.56714L1.33799 5.40698C1.37315 5.45386 1.44346 5.46558 1.49034 5.44214C1.49034 5.44214 2.65049 4.83276 3.22471 4.38745ZM14.0294 0.508545C14.0294 0.508545 13.8067 0.824951 13.2677 1.1062C13.1388 1.17651 12.9747 1.11792 12.9161 0.989014C12.8692 0.871826 12.9044 0.74292 13.0099 0.672607C13.0919 0.614014 13.1974 0.543701 13.2677 0.496826C13.4317 0.356201 13.5724 0.192139 13.5724 0.192139C13.5724 0.192139 13.7599 0.309326 14.0294 0.508545Z"
        fill="#757F3F"
      />
    </svg>
  );
};

import { FC } from "react";

export const PawnParachuteIcon: FC<{ height?: string; width?: string }> = ({
  height = "13",
  width = "14",
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 14 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.7865 8.91101H6.10664C6.05938 8.91101 6.01407 8.92978 5.98065 8.9632C5.94724 8.99661 5.92847 9.04193 5.92847 9.08918V9.60025H6.79119V10.5192H7.26475C7.43354 10.5192 7.56482 10.3833 7.56482 10.2192V9.21109C7.56482 9.04229 7.42885 8.91101 7.26475 8.91101H6.7865Z"
        fill="#BCBB85"
      />
      <path
        d="M5.68001 11.3303L6.07855 11.9961C6.0973 12.0242 6.08324 12.0618 6.05511 12.0664L5.46433 12.2493C5.44631 12.2536 5.42733 12.2505 5.41153 12.2409C5.39573 12.2312 5.38438 12.2157 5.37993 12.1977C5.37524 12.179 5.37993 12.1555 5.394 12.1415L5.56279 11.9305C5.58624 11.907 5.59092 11.8695 5.57217 11.8414L5.37524 11.5038L5.68001 11.3303ZM7.16633 12.5635L6.7631 12.193L6.99285 11.8695L7.51798 12.3618C7.54142 12.3853 7.55549 12.4228 7.5508 12.4603L7.48047 12.9292C7.4664 13.0136 7.3445 13.0276 7.31637 12.9432L7.20384 12.6244C7.19893 12.6003 7.18564 12.5787 7.16633 12.5635Z"
        fill="#88863B"
      />
      <path
        d="M7.69618 8.50769V8.97656C7.69618 9.05627 7.63053 9.12191 7.55083 9.12191H5.76443C5.68472 9.12191 5.61908 9.05627 5.61908 8.97656V8.50769H5.20179V8.97656C5.20179 9.28601 5.45498 9.5392 5.76443 9.5392H6.10202V10.3128H5.56282C5.37996 10.3128 5.21116 10.4066 5.11739 10.5613C5.02362 10.7161 5.01424 10.9083 5.10332 11.0724L5.34714 11.5929C5.39402 11.6773 5.48311 11.7335 5.57688 11.7335C5.61908 11.7335 5.66128 11.7241 5.69879 11.7054C5.82538 11.6397 5.87696 11.4803 5.81132 11.3537L5.56751 10.838H6.57089L6.39741 11.199C6.27081 11.4709 6.3177 11.7898 6.51462 12.0101L6.78188 12.3149C6.83346 12.3712 6.90379 12.404 6.97881 12.404C7.02925 12.4039 7.07858 12.3891 7.1208 12.3615C7.16303 12.3339 7.19633 12.2946 7.21668 12.2485C7.23703 12.2023 7.24355 12.1512 7.23545 12.1014C7.22735 12.0517 7.20498 12.0053 7.17104 11.968L6.90379 11.6632C6.8739 11.6318 6.85465 11.5918 6.84876 11.5489C6.84287 11.506 6.85063 11.4623 6.87096 11.4241L7.20855 10.7161C7.21324 10.7067 7.21793 10.6926 7.22262 10.6832C7.23668 10.6551 7.24137 10.6223 7.24137 10.5895V9.54858H7.55083C7.86028 9.54858 8.11347 9.29539 8.11347 8.98594V8.51707H7.69618V8.50769Z"
        fill="#68964D"
      />
      <path
        d="M6.10175 9.02576V9.84394C6.0545 9.84394 6.00918 9.82516 5.97577 9.79175C5.94236 9.75834 5.92358 9.71302 5.92358 9.66576V9.11719L6.10175 9.02576ZM7.24111 9.11719V9.84394C7.19386 9.84394 7.14854 9.82516 7.11512 9.79175C7.08171 9.75834 7.06294 9.71302 7.06294 9.66576V9.02576L7.24111 9.11719Z"
        fill="#BCBB85"
      />
      <path
        d="M6.46284 8.08557H6.79105C6.99266 8.08557 7.15208 8.24499 7.15676 8.45598V8.82639C7.15683 8.8375 7.15525 8.84856 7.15208 8.85921V8.88265C7.15208 9.14522 6.9364 9.3609 6.66914 9.3609H6.58474C6.53241 9.3609 6.48058 9.35059 6.43223 9.33056C6.38388 9.31053 6.33994 9.28118 6.30293 9.24417C6.26592 9.20716 6.23657 9.16323 6.21654 9.11487C6.19651 9.06652 6.1862 9.01469 6.1862 8.96236V8.66228H6.4769V8.59664H6.16745C6.12994 8.59664 6.10181 8.56851 6.10181 8.531V8.45129C6.10181 8.24968 6.26122 8.08557 6.46284 8.08557Z"
        fill="#88863B"
      />
      <path
        d="M6.78636 8.71875H7.15676V8.83128H6.78636V8.71875ZM6.10181 10.1066H7.24116V10.3129H6.10181V10.1066Z"
        fill="#BCBB85"
      />
      <path
        d="M7.02536 8.65765C7.04277 8.65765 7.05946 8.65073 7.07177 8.63842C7.08408 8.62611 7.091 8.60942 7.091 8.59201C7.091 8.5746 7.08408 8.5579 7.07177 8.54559C7.05946 8.53328 7.04277 8.52637 7.02536 8.52637C7.00795 8.52637 6.99125 8.53328 6.97894 8.54559C6.96663 8.5579 6.95972 8.5746 6.95972 8.59201C6.95972 8.60942 6.96663 8.62611 6.97894 8.63842C6.99125 8.65073 7.00795 8.65765 7.02536 8.65765Z"
        fill="#5092FF"
      />
      <path
        d="M7.62096 4.4543C7.62096 4.34646 7.70536 4.26207 7.8132 4.26207C7.92104 4.26207 8.00543 4.34646 8.00543 4.4543V8.04538C8.09311 8.0782 8.17048 8.13212 8.23049 8.20151L11.5154 5.39204C11.5544 5.36237 11.6032 5.34863 11.652 5.35362C11.7008 5.35861 11.7458 5.38195 11.778 5.41892C11.8102 5.45588 11.8272 5.5037 11.8254 5.55269C11.8236 5.60168 11.8033 5.64817 11.7686 5.68274L8.36647 8.59677C8.36137 8.74015 8.30086 8.87597 8.19768 8.97566C8.09449 9.07534 7.95667 9.13113 7.8132 9.13128C7.50843 9.13128 7.25993 8.88278 7.25993 8.57801V8.56395C7.25993 8.3267 7.41091 8.12321 7.62096 8.04491V6.73535H5.51105V8.04538C5.72204 8.12368 5.87208 8.3267 5.87208 8.56395V8.57801C5.87208 8.88278 5.62358 9.13128 5.31881 9.13128C5.01404 9.13128 4.76554 8.88278 4.76554 8.57801V8.56395C4.76554 8.54379 4.76695 8.52409 4.76929 8.5044L1.47173 5.68274C1.43698 5.64817 1.41667 5.60168 1.41492 5.55269C1.41317 5.5037 1.43011 5.45588 1.4623 5.41892C1.4945 5.38195 1.53954 5.35861 1.5883 5.35362C1.63707 5.34863 1.68591 5.36237 1.72492 5.39204L4.95215 8.1504C5.00279 8.10539 5.06187 8.06929 5.12657 8.04538V4.45383C5.12657 4.34599 5.21097 4.2616 5.31881 4.2616C5.42665 4.2616 5.51105 4.34599 5.51105 4.45383V6.35088H7.62096V4.4543ZM7.98199 8.56395C7.98199 8.47017 7.90697 8.39515 7.8132 8.39515C7.71942 8.39515 7.64441 8.47017 7.64441 8.56395V8.57801C7.64441 8.67179 7.71942 8.74681 7.8132 8.74681C7.90697 8.74681 7.98199 8.67179 7.98199 8.57801V8.56395Z"
        fill="#BCBB85"
      />
      <path
        d="M6.70187 8.59204H6.24238C6.19513 8.59204 6.14981 8.61081 6.11639 8.64423C6.08298 8.67764 6.06421 8.72296 6.06421 8.77021C6.06421 8.81747 6.08298 8.86278 6.11639 8.8962C6.14981 8.92961 6.19513 8.94838 6.24238 8.94838H6.24707C6.29396 8.94838 6.33615 8.92025 6.35022 8.87805L6.3596 8.84992C6.36423 8.83881 6.37204 8.82932 6.38205 8.82265C6.39206 8.81597 6.40383 8.81241 6.41586 8.81241C6.4279 8.81241 6.43966 8.81597 6.44967 8.82265C6.45968 8.82932 6.4675 8.83881 6.47213 8.84992L6.4815 8.87805C6.50026 8.92025 6.54246 8.94838 6.58466 8.94838H6.69249C6.79096 8.94838 6.87067 8.86867 6.87067 8.77021C6.88004 8.67644 6.80034 8.59204 6.70187 8.59204Z"
        fill="#BCBB85"
      />
      <path
        d="M6.24234 8.54993H6.70183C6.8228 8.54993 6.92361 8.65308 6.91283 8.77217C6.91233 8.83029 6.8889 8.88586 6.84762 8.92678C6.80635 8.96771 6.75058 8.99067 6.69246 8.99066H6.58462C6.55437 8.9898 6.52499 8.98035 6.49992 8.96341C6.47485 8.94648 6.45511 8.92275 6.44302 8.89502L6.44114 8.89126L6.4327 8.86501C6.43117 8.86184 6.42877 8.85916 6.42578 8.85729C6.4228 8.85542 6.41935 8.85443 6.41582 8.85443C6.4123 8.85443 6.40885 8.85542 6.40586 8.85729C6.40288 8.85916 6.40048 8.86184 6.39894 8.86501L6.39004 8.89126C6.37956 8.92069 6.36014 8.9461 6.33449 8.96392C6.30885 8.98175 6.27826 8.9911 6.24703 8.99066H6.24234C6.1839 8.99066 6.12784 8.96745 6.08652 8.92612C6.04519 8.88479 6.02197 8.82874 6.02197 8.7703C6.02197 8.71185 6.04519 8.6558 6.08652 8.61447C6.12784 8.57314 6.1839 8.54993 6.24234 8.54993ZM6.10637 8.7703C6.10637 8.84531 6.16732 8.90627 6.24234 8.90627H6.24703C6.2606 8.90671 6.27398 8.90294 6.28531 8.89546C6.29665 8.88799 6.30539 8.87719 6.31033 8.86454L6.32017 8.83406C6.32795 8.81514 6.34117 8.79896 6.35816 8.78757C6.37514 8.77619 6.39514 8.7701 6.41559 8.7701C6.43604 8.7701 6.45603 8.77619 6.47302 8.78757C6.49001 8.79896 6.50323 8.81514 6.511 8.83406L6.51194 8.83641L6.52085 8.86266C6.52653 8.87503 6.53546 8.88562 6.54669 8.8933C6.55792 8.90098 6.57103 8.90547 6.58462 8.90627H6.69246C6.76748 8.90627 6.82843 8.84531 6.82843 8.7703V8.76561C6.83499 8.69903 6.77685 8.63432 6.70183 8.63432H6.24234C6.20628 8.63432 6.17169 8.64865 6.14619 8.67415C6.1207 8.69965 6.10637 8.73423 6.10637 8.7703Z"
        fill="white"
      />
      <path
        d="M5.30488 8.7748H5.31426C5.41741 8.7748 5.49712 8.69509 5.49712 8.59663V8.49817H5.12671V8.59663C5.12671 8.69509 5.20642 8.7748 5.30488 8.7748ZM7.63048 8.60601C7.63048 8.70447 7.71018 8.78887 7.81333 8.78887C7.91649 8.78887 7.99619 8.70447 8.00088 8.60601V8.50755H7.63048V8.60601Z"
        fill="#88863B"
      />
      <path
        d="M8.53539 0.977594L9.37748 3.04766L8.0201 4.69058C7.54102 4.5976 7.05415 4.55066 6.56613 4.55038C6.07185 4.55184 5.57885 4.60067 5.09388 4.6962C5.09997 4.69394 5.10625 4.69222 5.11264 4.69105L3.5166 3.04766L4.59688 0.977594C4.60157 0.968217 4.61094 0.958839 4.62032 0.949462C5.60964 -0.316487 7.52732 -0.316487 8.51663 0.949462C8.52132 0.954151 8.5246 0.958839 8.52835 0.963528C8.53125 0.96776 8.53439 0.971829 8.53773 0.975719L8.53539 0.977594Z"
        fill="#BCBB85"
      />
      <path
        d="M1.52561 2.07006C1.52561 2.07006 1.79287 1.52148 2.4024 1.15576L4.41854 4.85983C3.63553 5.08958 3.02131 5.39435 2.64152 5.61472C2.36489 5.77882 2.06481 5.85384 1.76473 5.85384C1.53375 5.85415 1.30497 5.80888 1.09151 5.72063C0.878049 5.63238 0.684097 5.50288 0.520766 5.33954C0.357434 5.17621 0.227933 4.98226 0.139682 4.7688C0.0514301 4.55534 0.00616191 4.32656 0.00647051 4.09558C-0.115436 2.40296 1.52561 2.07006 1.52561 2.07006ZM13.1255 4.09089C13.1255 4.39565 13.0504 4.70042 12.8863 4.97705C12.6527 5.37886 12.2693 5.67164 11.8202 5.79118C11.371 5.91072 10.8928 5.84726 10.4904 5.61472C10.1481 5.4131 9.61831 5.14585 8.94313 4.93017L11.4657 1.84031C11.5594 1.9716 11.6063 2.06537 11.6063 2.06537C11.6063 2.06537 13.2474 2.39827 13.1255 4.09089Z"
        fill="#68964D"
      />
      <path
        d="M10.2701 0.949437C11.0297 1.19325 11.4329 1.77465 11.5595 1.99033L11.6064 2.07004L9.26673 5.04267C8.90101 4.9067 8.47902 4.78479 8.01953 4.69571L8.53529 0.97757C8.53529 0.97757 9.37457 0.658738 10.2701 0.949437Z"
        fill="#88863B"
      />
      <path
        d="M2.85723 0.949437C2.10234 1.19325 1.69912 1.77465 1.57252 1.98564L1.52563 2.06535L3.8653 5.03798C4.23102 4.90201 4.653 4.78011 5.11249 4.69102L4.59673 0.97757C4.59673 0.97757 3.75746 0.658738 2.85723 0.949437Z"
        fill="#848404"
      />
    </svg>
  );
};

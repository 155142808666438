import { ChangeEvent, FC, HTMLInputTypeAttribute } from "react";

export interface InputGenericProps {
  /**
   * Error message to display
   */
  error?: string;
  /**
   * HTML id attribute
   */
  id: string;
  /**
   * Label displayed above the input field that explains its' purpose
   */
  label?: string;
  /**
   * Maximum possible value to assign to the input
   */
  max?: any;
  /**
   * Minimum possible value to assign to the input
   */
  min?: any;
  /**
   * Placeholder text
   */
  placeholder?: string;
  /**
   * Indicates if input field should be read only
   */
  readOnly?: boolean;
  /**
   * Indicates if user has interacted with field (should be inherited from Formik)
   */
  touched?: boolean;
  /**
   * Input type
   */
  type?: HTMLInputTypeAttribute;
  /**
   * Input value
   */
  value: any;
  /**
   * Function to call when input value changes
   */
  handleChange?: (event: ChangeEvent<HTMLInputElement>) => void;
}

export const InputGeneric: FC<InputGenericProps> = ({
  error = "",
  id,
  label = "",
  max = null,
  min = null,
  placeholder = "",
  readOnly = false,
  touched = false,
  type = "text",
  value,
  handleChange = () => {},
}) => {
  return (
    <div className="mx-auto w-full max-w-sm">
      <label
        htmlFor={id}
        className="block text-sm px-3 font-medium text-semibold text-gray-700"
      >
        {label}
      </label>
      <div className={label ? "mt-1" : ""}>
        <input
          id={id}
          name={id}
          type={type}
          placeholder={placeholder}
          readOnly={readOnly}
          value={value}
          min={min}
          max={max}
          className="block w-full h-6 rounded border p-1 text-left shadow-lg transition focus:outline-none focus:ring-1 border-theme-olive hover:border-theme-dark-olive focus:ring-theme-olive sm:text-sm"
          onChange={handleChange}
        />
        {touched && error && <span className="text-red-500">{error}</span>}
      </div>
    </div>
  );
};

import { FC } from "react";

export const PawnTramIcon: FC<{ height?: string; width?: string }> = ({
  height = "12",
  width = "8",
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 8 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.49731 0.314087H3.12205V2.43074H2.49731V0.314087ZM4.87936 0.314087H5.5041V2.43074H4.87936V0.314087Z"
        fill="#757F3F"
      />
      <path
        d="M2.40437 11.603C2.40437 11.7083 2.36279 11.8092 2.28877 11.8837C2.21476 11.9581 2.11437 12 2.0097 12C1.79179 12 1.61597 11.8231 1.61597 11.603V10.0781C1.61597 9.85981 1.79273 9.68201 2.0097 9.68201C2.22761 9.68201 2.40437 9.85981 2.40437 10.0781V11.603ZM5.59633 11.603C5.59633 11.8222 5.77215 12 5.99006 12C6.20797 12 6.3838 11.8231 6.3838 11.603V10.0781C6.3838 9.97302 6.34231 9.87228 6.26847 9.79801C6.19463 9.72373 6.09449 9.68201 5.99006 9.68201C5.88564 9.68201 5.78549 9.72373 5.71165 9.79801C5.63781 9.87228 5.59633 9.97302 5.59633 10.0781V11.603Z"
        fill="#757F3F"
      />
      <path
        d="M8 8.89558C8 9.90216 7.19009 10.7159 6.18939 10.7159H1.81062C0.811784 10.7159 0 9.90122 0 8.89558V3.88148C0 2.87677 0.810849 2.06116 1.81062 2.06116H6.18939C7.18915 2.06116 8 2.87677 8 3.88148V8.89558Z"
        fill="#68964D"
      />
      <path
        d="M7.20267 5.97829C7.20267 6.52015 6.76591 6.95947 6.22722 6.95947H1.7755C1.2368 6.95947 0.800049 6.51921 0.800049 5.97829V3.64903C0.800049 3.10717 1.23774 2.66785 1.7755 2.66785H6.22722C6.76591 2.66785 7.20267 3.10717 7.20267 3.64903V5.97829Z"
        fill="#BCBB85"
      />
      <path
        d="M2.13021 8.8984C2.13021 9.26246 1.83561 9.55785 1.47367 9.55785C1.10987 9.55785 0.817139 9.26246 0.817139 8.8984C0.817139 8.53245 1.1108 8.23706 1.47367 8.23706C1.83561 8.23706 2.13021 8.53245 2.13021 8.8984ZM5.87021 8.8984C5.87021 9.26246 6.16481 9.55785 6.52675 9.55785C6.88868 9.55785 7.18328 9.26246 7.18328 8.8984C7.18328 8.53245 6.88962 8.23706 6.52675 8.23706C6.16481 8.23706 5.87021 8.53245 5.87021 8.8984Z"
        fill="white"
      />
      <path
        d="M1.76932 1.238C1.7263 1.238 1.68141 1.2286 1.63839 1.20884C1.5639 1.17447 1.50595 1.11184 1.47722 1.03464C1.44848 0.957437 1.45128 0.871932 1.48501 0.796801C1.49997 0.764816 1.85349 0.000940733 2.80837 0.000940733C2.98045 0.000940733 3.12073 0.142051 3.12073 0.315145C3.12073 0.48824 2.98045 0.62935 2.80837 0.62935C2.26406 0.62935 2.06111 1.04139 2.0527 1.05927C2.02664 1.1126 1.98632 1.15758 1.93627 1.18916C1.88621 1.22073 1.8284 1.23764 1.76932 1.238ZM6.23039 1.23989C6.11162 1.23989 5.99939 1.17215 5.94701 1.05738C5.93579 1.03481 5.73191 0.62841 5.19134 0.62841C5.01926 0.62841 4.87898 0.4873 4.87898 0.314205C4.87898 0.14111 5.01926 0 5.19134 0C6.14622 0 6.49974 0.763875 6.5147 0.79586C6.58578 0.953903 6.51564 1.14017 6.35852 1.21072C6.31643 1.23142 6.27248 1.23989 6.23039 1.23989Z"
        fill="#757F3F"
      />
      <path
        d="M5.19169 0.62841H2.80871C2.63662 0.62841 2.49634 0.4873 2.49634 0.314205C2.49634 0.14111 2.63662 0 2.80871 0H5.19075C5.36283 0 5.50312 0.14111 5.50312 0.314205C5.50312 0.48824 5.36377 0.62841 5.19169 0.62841Z"
        fill="#757F3F"
      />
    </svg>
  );
};

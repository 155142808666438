import { FC, MouseEvent, ReactNode } from "react";

export interface ButtonProps {
  /**
   * Children
   */
  children: ReactNode;
  /**
   * String of class names to be used
   */
  className?: string;
  /**
   * Function to call on click
   */
  onClick?: (e: MouseEvent<HTMLButtonElement>) => void;
}

export const Button: FC<ButtonProps> = ({
  children,
  className = "",
  onClick = () => {},
}) => {
  return (
    <button className={className} onClick={onClick}>
      {children}
    </button>
  );
};

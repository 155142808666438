import { FC, Fragment, ReactNode } from "react";
import { Dialog, Transition } from "@headlessui/react";

export interface ModalProps {
  children: ReactNode;
  /**
   * Modal visibility state
   */
  open: boolean;
  /**
   * Function that toggles modal state
   */
  setOpen: (value: boolean) => void;
  /**
   * Tailwind sizing utility, determins the width of the modal
   */
  maxWidth?: "max-w-sm" | "max-w-xl";
  /**
   * Modal visibility state
   */
  visible?: boolean;
  /**
   * Function that toggles modal state
   */
  setVisible?: (value: boolean) => void;
}

export const Modal: FC<ModalProps> = ({
  children,
  open,
  setOpen,
  maxWidth = "max-w-sm",
  visible = true,
  setVisible = () => {},
}) => {
  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-10"
        onClose={() => {
          setVisible(true);
        }}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div
            className={`fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity ${
              visible ? "" : "hidden"
            }`}
          />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel
                className={`relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full ${maxWidth} sm:p-6 ${
                  visible ? "" : "hidden"
                }`}
              >
                {children}
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>

        <div className="fixed bottom-10 left-10 z-10">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Dialog.Panel
              className={`relative transform overflow-hidden rounded-lg bg-white border border-theme-sand px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 w-fit sm:p-6 ${
                visible ? "hidden" : ""
              }`}
              onClick={() => {
                setVisible(true);
              }}
            >
              Click anywhere to return to the overlay.
            </Dialog.Panel>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

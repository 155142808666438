import { FC } from "react";

export const PawnHorseIcon: FC<{ height?: string; width?: string }> = ({
  height = "12",
  width = "13",
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 13 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.15252 11.4184L2.27262 10.5377C2.27262 10.5377 2.09248 10.5377 1.91233 10.4977C1.85228 10.6578 1.79223 10.938 1.73218 11.2783C1.95236 11.3984 2.15252 11.4184 2.15252 11.4184Z"
        fill="#A09F60"
      />
      <path
        d="M5.65845 2.08504L5.9589 1.70447L6.51975 3.02647L5.65845 3.38701V2.08504Z"
        fill="#FED0AC"
      />
      <path
        d="M5.33813 2.92553C5.6584 2.98558 5.6584 3.38591 5.6584 3.38591C5.99868 3.24579 5.95865 2.46515 5.79851 2.0448L5.33813 2.92553Z"
        fill="#E0A372"
      />
      <path
        d="M5.21811 0.262207L3.91614 1.42397C3.87608 1.86463 4.07638 1.98481 4.13648 2.14506C4.15651 2.20515 4.07638 2.38542 4.03632 2.48557C3.93617 2.70591 4.25666 2.70591 4.35681 2.64581C4.47699 2.766 4.33678 2.94627 4.73738 2.66585C4.73738 2.66585 4.35681 3.06645 4.71735 3.00636C4.75742 3.20666 4.8776 3.56721 5.67881 2.80606C5.91917 2.58572 5.81902 2.04491 5.81902 2.04491L6.25969 1.76448L5.21811 0.262207Z"
        fill="#FED0AC"
      />
      <path
        d="M4.07603 0.562739C4.93733 -0.178383 5.69849 0.102042 5.89879 0.302345C6.39955 0.803103 6.47967 1.58429 6.21927 2.28535C6.21927 2.28535 5.95888 2.28535 5.79864 2.02495C5.79864 2.02495 6.09909 2.02495 5.97891 1.4641C5.87876 0.983376 5.31791 0.943315 5.33794 1.64438L5.27785 1.66441C5.01745 1.44407 5.09758 0.943315 4.69697 0.983376C4.33642 1.00341 3.89576 1.50416 3.89576 1.50416C3.71548 1.36395 3.71548 0.883224 4.07603 0.562739Z"
        fill="#DBB471"
      />
      <path
        d="M4.49691 1.98469C4.53695 2.08477 4.51693 2.18486 4.45688 2.20487C4.39683 2.22489 4.33678 2.16484 4.29675 2.06476C4.25671 1.96467 4.2367 1.88461 4.31676 1.86459C4.37681 1.82456 4.45688 1.88461 4.49691 1.98469Z"
        fill="#664E27"
      />
      <path
        d="M5.51846 1.70415C5.43839 1.524 5.53848 1.2838 5.67859 1.22375C5.67859 1.22375 5.49844 1.56404 5.69861 1.82425C5.69861 1.82425 5.55849 1.84427 5.51846 1.70415Z"
        fill="#E0A372"
      />
      <path
        d="M4.8571 2.54578C4.8571 2.54578 4.737 2.76596 4.67695 2.82601C4.57686 2.94611 4.53683 2.92609 4.57686 2.82601C4.6169 2.74594 4.71698 2.64586 4.71698 2.64586L4.8571 2.54578Z"
        fill="#664E27"
      />
      <path
        d="M5.97862 2.32466L5.79847 2.42474L5.01782 1.30382L5.19797 1.20374L5.97862 2.32466Z"
        fill="#3E4347"
      />
      <path
        d="M5.93893 2.38474L5.73877 2.34471L6.07905 1.42395L6.27922 1.46398L5.93893 2.38474Z"
        fill="#3E4347"
      />
      <path
        d="M5.70752 2.21785L5.96233 2.16541L6.11185 2.8908L5.85684 2.94325L5.70752 2.21785Z"
        fill="#3E4347"
      />
      <path
        d="M6.66013 1.50411C6.6401 1.78453 5.7187 1.40396 5.17788 1.38393C4.57697 1.3639 3.67561 1.60426 3.69564 1.38393C3.7357 0.582714 4.41673 -0.0382261 5.23798 0.00183454C6.03919 0.0218649 6.68016 0.702896 6.66013 1.50411Z"
        fill="#68964D"
      />
      <path
        d="M5.6781 3.34692L5.09762 4.34775L4.87744 4.42782L4.99754 4.62798L5.51797 4.58795L6.07843 3.46702L5.6781 3.34692Z"
        fill="#68964D"
      />
      <path
        d="M7.66162 4.22839C7.66162 4.22839 6.1994 5.35009 6.09925 5.47027C5.9991 5.59045 5.93901 5.69061 5.85889 5.97103L6.43977 6.23143C6.43977 6.21139 7.70168 4.52885 7.66162 4.22839Z"
        fill="#3E4347"
      />
      <path
        d="M11.7881 6.05107C12.3089 5.24986 10.2057 4.82922 9.36447 5.97095V6.23135C9.36447 6.23135 11.0871 5.73059 10.6865 6.71207C10.2458 7.79371 12.0085 8.71511 12.0085 8.71511C12.0085 8.71511 11.067 7.1928 11.7881 6.05107ZM1.4525 2.04501C1.4525 2.04501 1.31228 1.74455 1.3924 1.20374C1.3924 1.20374 1.79301 1.48416 2.07344 2.1051L1.4525 2.04501Z"
        fill="#88863B"
      />
      <path
        d="M5.05771 9.21563C4.9175 9.31578 4.75726 9.456 4.63708 9.57618L3.93602 9.37587C3.93602 9.37587 4.57699 8.41442 6.07926 7.77345C6.07926 7.77345 4.37668 7.15251 3.7958 7.97375C3.7958 7.97375 3.03465 9.11548 2.77426 9.37587C2.51386 9.6563 2.79429 9.89666 2.91447 9.93672C3.03465 9.97678 4.85741 10.137 4.85741 10.137C4.85741 10.137 5.09777 9.93672 5.41826 9.81654C5.29808 9.47603 5.15786 9.29575 5.05771 9.21563Z"
        fill="#88863B"
      />
      <path
        d="M5.31758 9.0155C5.31758 9.0155 5.19749 9.09557 5.03735 9.21567C5.13744 9.31575 5.27755 9.4959 5.41767 9.77613C5.55778 9.71608 5.73793 9.67605 5.87805 9.69607C5.89806 9.69607 5.65787 9.17564 5.31758 9.0155Z"
        fill="#A09F60"
      />
      <path
        d="M7.28134 6.87219L7.12109 8.03395C7.12109 8.03395 8.70349 8.29434 9.36449 8.05398C9.36449 8.05398 9.94537 9.49616 11.047 10.3975C11.1272 10.2573 11.2273 10.017 11.2874 9.67644C10.8668 9.41604 10.0719 8.67981 9.77145 7.39787C9.77145 7.39787 8.62337 7.67341 7.28134 6.87219Z"
        fill="#88863B"
      />
      <path
        d="M11.0469 10.3971C11.187 10.5172 11.3471 10.6373 11.5073 10.7374C11.5073 10.7374 11.6674 10.3371 11.6874 9.87668C11.6874 9.87668 11.5273 9.83665 11.2871 9.67651C11.227 10.0168 11.1269 10.257 11.0469 10.3971Z"
        fill="#A09F60"
      />
      <path
        d="M8.58303 5.2097C6.98061 5.41001 5.43827 5.81061 5.15785 4.92928C3.99609 1.34385 1.81278 1.92473 1.81278 1.92473C1.43221 1.46403 1.05163 1.38391 1.05163 1.38391C1.01157 1.84461 1.19184 2.22518 1.19184 2.22518C1.07166 2.78603 0.110206 4.62882 0.0100546 4.86919C-0.090097 5.10955 0.590934 5.55022 0.590934 5.55022C0.731146 5.53019 0.771207 5.28982 1.07166 5.30985C1.15178 5.30985 0.931449 5.59028 1.01157 5.63034C1.17181 5.69043 1.35209 5.22973 1.6926 5.04946C2.91445 4.38846 2.51384 3.94779 2.51384 3.94779C3.0146 4.28831 2.73418 5.04946 2.51384 5.45007C2.01309 6.39149 2.53387 7.75355 2.53387 7.75355C2.03312 8.13413 0.95148 8.35446 0.95148 8.35446C0.570904 10.3174 1.25193 11.0385 1.73266 11.2989C1.79275 10.9584 1.85284 10.678 1.91293 10.5177C1.73266 10.4777 1.53236 10.3575 1.45224 10.1572C1.31203 9.73655 1.59245 8.79513 1.59245 8.79513C2.89442 8.95537 3.69563 8.55476 4.63706 8.3144C5.55845 8.09407 6.45982 8.25431 7.36118 8.39452C10.7663 8.99543 10.3257 4.98937 8.58303 5.2097Z"
        fill="#BCBB85"
      />
      <path
        d="M7.16089 8.25425C8.04222 10.2573 9.96513 9.15562 9.96513 9.15562C9.52447 10.3775 9.84495 11.2187 10.1254 11.6594C10.3657 11.5392 10.506 11.2588 10.5861 11.0986C9.96513 10.3975 10.4058 9.4761 10.526 9.11555C10.6662 8.71495 10.4659 8.45455 10.4659 8.45455C9.92507 8.33437 9.62462 8.69492 8.98365 7.87368C8.34268 7.07246 7.16089 8.25425 7.16089 8.25425Z"
        fill="#88863B"
      />
      <path
        d="M10.4058 12.0001C10.7063 11.9801 10.8064 11.2991 10.8064 11.2991C10.7263 11.239 10.6462 11.1589 10.5861 11.0988C10.506 11.259 10.3658 11.5394 10.1254 11.6596C10.2656 11.8799 10.4058 12.0001 10.4058 12.0001ZM6.43983 7.23291C6.43983 7.23291 6.49992 7.67358 6.19946 8.27449C6.19946 8.27449 6.43983 8.27449 6.64013 8.29452C6.64013 8.29452 6.8204 8.09421 6.88049 7.27297L6.43983 7.23291Z"
        fill="#A09F60"
      />
      <path
        d="M5.25806 5.1095C5.25806 5.1095 5.73878 5.63029 5.43833 6.07095C5.03772 6.67186 6.25957 7.77353 7.64166 7.15259C8.62315 6.71192 7.68173 5.63029 8.12239 5.20965C8.10236 5.22968 6.45988 5.59022 5.25806 5.1095Z"
        fill="#88863B"
      />
      <path
        d="M0.691216 4.66883L0.591064 4.56868C1.73279 3.50707 1.99319 3.06641 1.99319 3.06641L2.11337 3.14653C2.11337 3.16656 1.85297 3.60722 0.691216 4.66883Z"
        fill="#3E4347"
      />
      <path
        d="M2.2535 3.26668C2.09326 3.02632 1.89295 2.80599 1.69265 2.60568C1.49235 2.40538 1.27201 2.2852 1.17186 2.20508C1.17186 2.20508 1.15183 2.30523 1.1318 2.36532C1.21192 2.44544 1.41223 2.54559 1.5925 2.72587C1.87292 3.00629 2.23347 3.50705 2.55395 4.22814C2.59401 4.20811 2.6541 4.10796 2.67413 4.10796C2.53392 3.8075 2.43377 3.52708 2.2535 3.26668ZM0.971558 5.32981C1.01162 5.30978 1.11177 5.32981 1.11177 5.32981C0.871406 4.68884 0.49083 4.2682 0.350618 4.18808L0.290527 4.30826C0.330588 4.32829 0.390679 4.36835 0.4708 4.44847C0.590982 4.60872 0.831346 4.94923 0.971558 5.32981Z"
        fill="#3E4347"
      />
      <path
        d="M1.07166 3.38695C1.07166 3.38695 1.27196 3.46707 1.41217 3.30683C1.55238 3.14659 1.47226 2.72595 1.47226 2.72595C1.47226 2.72595 1.19184 2.94629 1.07166 3.38695ZM0.190322 4.80911C0.190322 4.80911 0.450716 4.76904 0.370595 5.08953C0.270443 5.38998 0.170292 4.90926 0.190322 4.80911Z"
        fill="#88863B"
      />
      <path
        d="M3.91626 4.82903C3.29532 4.809 2.59426 4.6087 2.13356 4.42843C1.63281 4.24816 1.25223 4.04785 1.25223 4.04785L1.19214 4.148C1.19214 4.148 1.57271 4.34831 2.07347 4.52858C2.37393 4.64876 2.67438 4.72888 2.95481 4.78897C3.27529 4.86909 3.57575 4.90916 3.83614 4.90916C3.8762 4.90916 3.91626 4.82903 3.91626 4.82903Z"
        fill="#3E4347"
      />
      <path
        d="M5.35794 4.46863C5.35794 4.46863 4.15612 4.6489 4.01591 4.68896C4.01591 4.68896 4.03594 4.76908 3.93579 4.92933L5.63837 5.06954L6.37949 3.94784L7.6414 4.22827C7.6414 4.22827 8.24231 4.2483 8.84322 3.68745C8.84322 3.68745 7.68146 2.44557 6.27934 2.52569C6.27934 2.52569 6.059 2.98639 5.59831 3.20672L5.35794 4.46863Z"
        fill="#68964D"
      />
      <path
        d="M7.66135 4.2282C7.66135 4.2282 6.49959 5.20968 6.25923 5.79056C6.09899 6.19117 6.51963 6.77205 6.59975 6.97235C6.59975 6.97235 7.04041 7.01241 7.30081 6.75202C7.30081 6.75202 7.32084 6.53168 7.00035 6.01089C7.00035 6.01089 8.16211 5.59026 8.62281 5.18965C9.08351 4.78905 9.20369 4.38844 8.86317 3.68738L7.66135 4.2282Z"
        fill="#3E4347"
      />
      <path
        d="M6.78053 8.17426C6.78053 8.17426 6.03941 8.57487 5.99935 8.69505C5.95929 8.81523 6.11953 8.87532 6.11953 8.87532C6.11953 8.87532 6.7605 8.95544 7.48159 8.77517L7.30132 6.69202C7.30132 6.69202 7.12105 6.93238 6.54017 6.97244L6.78053 8.17426Z"
        fill="#333333"
      />
      <path
        d="M4.05581 4.78876V4.56858C4.05581 4.48852 3.99576 4.42847 3.91569 4.42847H3.81561C3.75556 4.42847 3.71553 4.4685 3.69551 4.50853C3.55539 4.50853 3.37524 4.56858 3.37524 4.8288C3.37524 4.86883 3.37524 5.10903 3.65548 5.10903C4.05581 5.10903 4.05581 5.10903 4.05581 4.78876Z"
        fill="#FED0AC"
      />
      <path
        d="M5.49865 1.424C5.49865 1.424 3.67589 1.96482 3.39546 1.92476C3.11504 1.8847 3.5557 1.50413 3.67589 1.40397C3.79607 1.30382 5.37846 1.28379 5.49865 1.424Z"
        fill="#88863B"
      />
    </svg>
  );
};

import { FC } from "react";

export const PawnCarIcon: FC<{ height?: string; width?: string }> = ({
  height = "8",
  width = "12",
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 12 8"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.6667 3.47828C10.6667 3.47828 10 0.695679 9 0.695679H5.33333C4.33333 0.695679 3 3.13046 3 3.13046C3 3.13046 0.333333 3.47828 0.333333 4.86959V5.56524C0.333333 5.59654 0.340333 5.62611 0.342 5.65672C0.238613 5.71654 0.152406 5.80408 0.0922837 5.91029C0.032161 6.0165 0.000302804 6.13753 0 6.26089C0 6.44539 0.0702379 6.62233 0.195262 6.75279C0.320286 6.88325 0.489856 6.95654 0.666667 6.95654H10.6667C11.0203 6.95654 11.3594 6.80996 11.6095 6.54904C11.8595 6.28812 12 5.93424 12 5.56524V4.86959C12 4.50059 11.8595 4.14671 11.6095 3.88579C11.3594 3.62487 11.0203 3.47828 10.6667 3.47828Z"
        fill="#68964D"
      />
      <path
        d="M0.333333 4.86955V5.56521C0.333333 5.59651 0.340333 5.62608 0.342 5.65668C0.238613 5.7165 0.152406 5.80404 0.0922837 5.91025C0.032161 6.01647 0.000302804 6.1375 0 6.26086C0 6.44536 0.0702379 6.6223 0.195262 6.75276C0.320286 6.88322 0.489856 6.95651 0.666667 6.95651H10.6667C11.0203 6.95651 11.3594 6.80993 11.6095 6.54901C11.8595 6.28809 12 5.9342 12 5.56521V4.86955C12 4.74851 11.9803 4.63338 11.9527 4.52173H0.400333C0.357924 4.63246 0.335219 4.75033 0.333333 4.86955Z"
        fill="#88863B"
      />
      <path
        d="M5.30325 6.95651C5.32158 6.84312 5.33325 6.72764 5.33325 6.60868C5.33325 6.05519 5.12254 5.52436 4.74747 5.13298C4.37239 4.7416 3.86368 4.52173 3.33325 4.52173C2.80282 4.52173 2.29411 4.7416 1.91904 5.13298C1.54397 5.52436 1.33325 6.05519 1.33325 6.60868C1.33325 6.72764 1.34492 6.84312 1.36325 6.95651H5.30325ZM10.9699 6.95651C10.9883 6.84312 10.9999 6.72764 10.9999 6.60868C10.9999 6.05519 10.7892 5.52436 10.4141 5.13298C10.0391 4.7416 9.53035 4.52173 8.99992 4.52173C8.46949 4.52173 7.96078 4.7416 7.5857 5.13298C7.21063 5.52436 6.99992 6.05519 6.99992 6.60868C6.99992 6.72764 7.01159 6.84312 7.02992 6.95651H10.9699Z"
        fill="#BCBB85"
      />
      <path
        d="M3.33333 8.00001C4.06971 8.00001 4.66667 7.37711 4.66667 6.60871C4.66667 5.84031 4.06971 5.21741 3.33333 5.21741C2.59695 5.21741 2 5.84031 2 6.60871C2 7.37711 2.59695 8.00001 3.33333 8.00001Z"
        fill="#68964D"
      />
      <path
        d="M3.33341 7.30439C3.7016 7.30439 4.00008 6.99294 4.00008 6.60874C4.00008 6.22454 3.7016 5.91309 3.33341 5.91309C2.96522 5.91309 2.66675 6.22454 2.66675 6.60874C2.66675 6.99294 2.96522 7.30439 3.33341 7.30439Z"
        fill="#BCBB85"
      />
      <path
        d="M9.00008 8.00001C9.73646 8.00001 10.3334 7.37711 10.3334 6.60871C10.3334 5.84031 9.73646 5.21741 9.00008 5.21741C8.2637 5.21741 7.66675 5.84031 7.66675 6.60871C7.66675 7.37711 8.2637 8.00001 9.00008 8.00001Z"
        fill="#68964D"
      />
      <path
        d="M8.99992 7.30439C9.36811 7.30439 9.66659 6.99294 9.66659 6.60874C9.66659 6.22454 9.36811 5.91309 8.99992 5.91309C8.63173 5.91309 8.33325 6.22454 8.33325 6.60874C8.33325 6.99294 8.63173 7.30439 8.99992 7.30439Z"
        fill="#BCBB85"
      />
      <path
        d="M7.33342 0.347826C7.33342 0.440075 7.2983 0.528546 7.23578 0.593776C7.17327 0.659006 7.08849 0.695652 7.00008 0.695652H6.33342C6.24501 0.695652 6.16022 0.659006 6.09771 0.593776C6.0352 0.528546 6.00008 0.440075 6.00008 0.347826C6.00008 0.255577 6.0352 0.167106 6.09771 0.101876C6.16022 0.0366459 6.24501 0 6.33342 0H7.00008C7.08849 0 7.17327 0.0366459 7.23578 0.101876C7.2983 0.167106 7.33342 0.255577 7.33342 0.347826ZM7.66675 3.13043H10.0001C10.0001 3.13043 9.33342 1.3913 9.00008 1.3913H7.66675V3.13043ZM7.00008 1.3913H5.33342C4.66675 1.3913 3.66675 3.13043 3.66675 3.13043H7.00008V1.3913Z"
        fill="#BCBB85"
      />
    </svg>
  );
};

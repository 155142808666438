import { FC } from "react";

export const PawnFormulaIcon: FC<{ height?: string; width?: string }> = ({
  height = "9",
  width = "12",
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 12 9"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.09229 4.90799L10.0113 4.36049C10.3242 4.12584 10.8717 4.36049 11.2236 4.88844C11.556 5.41638 11.5756 6.02255 11.2627 6.25719C11.2432 6.27675 11.2236 6.2963 11.1845 6.2963L10.3242 6.80469L9.09229 4.90799Z"
        fill="#3E4347"
      />
      <path
        d="M9.20978 6.25717C9.5422 6.78512 10.0115 7.03932 10.3439 6.80468C10.6568 6.57003 10.6568 5.96387 10.3048 5.43592C9.97238 4.90797 9.42487 4.67333 9.09246 4.90797C8.79916 5.12306 8.95559 5.88565 9.20978 6.25717Z"
        fill="#88863B"
      />
      <path
        d="M9.46387 6.08126C9.63985 6.35501 9.87449 6.49189 10.0505 6.35501C10.2265 6.23769 10.2069 5.90528 10.0309 5.63153C9.85494 5.35777 9.56163 5.2209 9.38565 5.35777C9.22922 5.4751 9.28788 5.80751 9.46387 6.08126Z"
        fill="#BCBB85"
      />
      <path
        d="M9.62019 5.67062C9.79617 5.57286 10.0308 5.82705 9.9135 6.00304L9.1509 6.47232L8.91626 6.0617L9.62019 5.67062Z"
        fill="white"
      />
      <path
        d="M8.93573 4.77112V5.82702L8.13403 5.08398L8.93573 4.77112Z"
        fill="#68964D"
      />
      <path
        d="M5.18188 1.48616L6.23778 0.860443C6.6093 0.586692 7.23502 0.860443 7.62609 1.46661C8.01717 2.07277 8.03672 2.7767 7.6652 3.05045C7.64565 3.07001 7.60654 3.08956 7.58698 3.08956L6.6093 3.67617L5.18188 1.48616Z"
        fill="#3E4347"
      />
      <path
        d="M5.29911 3.03082C5.69018 3.63698 6.23768 3.93028 6.6092 3.65653C6.98072 3.38278 6.96117 2.67885 6.57009 2.07269C6.17902 1.46652 5.5533 1.19277 5.18178 1.46652C4.82982 1.72072 5.02535 2.60063 5.29911 3.03082Z"
        fill="#88863B"
      />
      <path
        d="M9.60081 7.80181L7.37169 6.56993L6.80463 6.90234L4.53641 6.39394L1.74023 4.28215V3.28491L4.53641 5.24028L6.80463 6.06153L7.37169 5.65091L9.60081 7.44984V7.80181Z"
        fill="#68964D"
      />
      <path
        d="M10.4221 6.922L9.60081 7.44995L7.37169 5.65101L6.80463 6.06164L4.53641 5.24039L1.74023 3.28502L2.44417 2.87439L2.91345 2.99172L4.53641 2.09225L4.51685 1.87716L5.16212 1.48608L8.07562 3.4219L8.93598 4.7711L8.32982 5.0644L10.4221 6.922Z"
        fill="#88863B"
      />
      <path
        d="M2.65942 1.32971V2.19007L4.73211 3.18731L5.65113 2.75713L3.22648 1.81855V1.32971H2.65942Z"
        fill="#68964D"
      />
      <path
        d="M5.65102 3.50017V2.75713L4.732 3.18731L2.65931 2.19007V1.32971H2.54199V2.26829L4.732 3.91079L5.65102 3.50017Z"
        fill="#BCBB85"
      />
      <path
        d="M8.07583 3.42188L8.93619 4.77108L6.80485 6.06162L4.53662 5.24037L8.07583 3.42188Z"
        fill="#68964D"
      />
      <path
        d="M8.07573 3.42188L8.93609 4.77108L8.70145 4.90795L7.82153 3.5392L8.07573 3.42188Z"
        fill="#BCBB85"
      />
      <path
        d="M5.18214 1.48608L8.07608 3.4219L7.82188 3.53922L4.94749 1.62296L5.18214 1.48608ZM4.9866 5.00574L7.11795 5.8661L6.80509 6.06164L4.53687 5.24039L4.9866 5.00574Z"
        fill="#BCBB85"
      />
      <path
        d="M2.09244 3.06982L4.98639 5.00564L4.53665 5.24028L1.74048 3.28491L2.09244 3.06982Z"
        fill="#BCBB85"
      />
      <path
        d="M4.7321 3.91066C4.67344 4.32128 5.29916 4.84923 5.90532 4.92745C6.96122 5.08388 7.45006 4.47771 7.1372 3.852C6.8439 3.28494 5.67068 3.40226 5.67068 3.40226L4.7321 3.91066Z"
        fill="#BCBB85"
      />
      <path
        d="M0.58667 4.08669L1.799 3.36321C2.22918 3.05035 2.93311 3.36321 3.36329 4.04759C3.81302 4.73196 3.83258 5.53366 3.4024 5.82697C3.38284 5.84652 3.34374 5.86608 3.30463 5.88563L2.19007 6.55046L0.58667 4.08669Z"
        fill="#3E4347"
      />
      <path
        d="M0.723456 5.84655C1.17319 6.53093 1.77935 6.86334 2.20953 6.55048C2.63971 6.23762 2.60061 5.45548 2.17043 4.7711C1.72069 4.08672 1.01676 3.77386 0.606134 4.08672C0.195507 4.38002 0.410597 5.35771 0.723456 5.84655Z"
        fill="#88863B"
      />
      <path
        d="M1.0753 5.61186C1.30994 5.98338 1.60325 6.13981 1.83789 5.98338C2.05298 5.82695 2.05298 5.39677 1.81834 5.02525C1.56414 4.67329 1.19262 4.4973 0.957975 4.67329C0.742885 4.82972 0.840653 5.24034 1.0753 5.61186Z"
        fill="#BCBB85"
      />
      <path
        d="M8.11458 6.55051C8.29056 6.45274 8.5252 6.70694 8.40788 6.88292L7.64529 7.35221L7.41064 6.94158L8.11458 6.55051Z"
        fill="white"
      />
      <path
        d="M5.76831 6.70699L6.82421 6.08127C7.19573 5.80752 7.82144 6.08127 8.21252 6.68743C8.60359 7.2936 8.62315 7.99753 8.25163 8.27128C8.23207 8.29083 8.19296 8.31039 8.17341 8.31039L7.19573 8.897L5.76831 6.70699Z"
        fill="#3E4347"
      />
      <path
        d="M5.88553 8.27129C6.2766 8.87746 6.82411 9.17076 7.19563 8.89701C7.56715 8.62326 7.54759 7.91933 7.15652 7.31316C6.76545 6.707 6.13973 6.43325 5.76821 6.707C5.41624 6.9612 5.61178 7.84111 5.88553 8.27129Z"
        fill="#88863B"
      />
      <path
        d="M6.19855 8.05621C6.41364 8.36906 6.66784 8.52549 6.86337 8.38862C7.05891 8.25174 7.05891 7.86067 6.84382 7.54781C6.62873 7.23495 6.29632 7.07852 6.10078 7.23495C5.90524 7.37183 5.98346 7.74335 6.19855 8.05621Z"
        fill="#BCBB85"
      />
      <path
        d="M4.04761 0L4.82975 0.703932L1.19277 2.62019L0 1.52519L4.04761 0Z"
        fill="#68964D"
      />
      <path
        d="M5.00586 3.85204C5.00586 4.43865 5.70979 4.73196 6.25729 4.73196C6.82435 4.73196 7.01989 4.43865 7.01989 3.85204C7.01989 3.26543 6.57015 2.79614 6.0031 2.79614C5.45559 2.79614 5.00586 3.26543 5.00586 3.85204Z"
        fill="#68964D"
      />
      <path
        d="M5.6117 3.8325C5.63125 3.61741 6.37429 3.61741 6.94134 3.5783C7.11733 3.55875 7.09777 4.22357 6.94134 4.24313C6.04188 4.32134 5.59214 4.04759 5.6117 3.8325Z"
        fill="#3E4347"
      />
      <path
        d="M11.341 6.23755L11.732 7.09791L9.17051 8.81863L8.01685 8.29068L10.3242 6.86327L11.341 6.23755Z"
        fill="#68964D"
      />
      <path
        d="M0.547607 1.31006L1.79904 2.3073L2.15101 2.13131L1.0169 1.13408L0.547607 1.31006ZM3.36333 0.254163L4.20414 1.03631L4.45834 0.899434L3.67619 0.136841L3.36333 0.254163ZM10.7937 6.58955L11.2239 7.43036L11.439 7.29348L11.0479 6.43312L10.7937 6.58955ZM8.5255 7.97786L9.48363 8.60357L9.69872 8.44715L8.79925 7.82143L8.5255 7.97786Z"
        fill="#BCBB85"
      />
    </svg>
  );
};

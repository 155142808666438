import { FC, useState } from "react";
import { Button, GamePawn, GamePoint, Modal } from "..";
import { coordinates, points } from "../../constants";
import { TeamType } from "../../store/teams";
import { useTopicStore } from "../../store/topic";
import { useMapStore } from "../../store/map";
import { useMultipleMapsStore } from "../../store/multipleMaps";

import { useTranslation } from "react-i18next";

export interface MapOverlayProps {
  /**
   * Active teams
   */
  teams: Array<TeamType>;
  /**
   * Function to call when a pawn is moved to a game point
   */
  onPawnMoved: (toggleQuestionModal: boolean, teamId: number) => void;
}

const mapName: { [key: string]: string } = {
  Solin: "./NFESolin.png",
  Split: "./NFESplit.png",
  Zreče: "./NFESlovenia.png",
  "Vila de Marmeleira": "./NFEPortugal.png",
  Antwerp: "./NFEBelgium.png",
};

export const MapOverlay: FC<MapOverlayProps> = ({ teams, onPawnMoved }) => {
  const { t, i18n } = useTranslation(["translation", "en"]);

  const [winModal, setWinModal] = useState<boolean>(false);
  const topic = useTopicStore((state) => state.topic);
  const map = useMapStore((state) => state.map);
  const multipleMaps = useMultipleMapsStore((state) => state.multipleMaps);
  const setMultipleMaps = useMultipleMapsStore(
    (state) => state.setMultipleMaps
  );

  const checkWinCondition = () => {
    let totals = {
      chips: 0,
      heads: 0,
      hands: 0,
      hearts: 0,
    };

    for (const team of teams) {
      totals.chips += team.chips;
      totals.heads += team.heads;
      totals.hands += team.hands;
      totals.hearts += team.hearts;
    }

    const score = totals.chips + totals.heads + totals.hands + totals.hearts;

    if (!points[topic as keyof typeof points]) return;

    if (score >= points[topic as keyof typeof points].total) {
      if (t(`${topic}.treasure`).length > 0) {
        return t(`${topic}.treasure`);
      }
      return "Congratulations, you won!\n";
    } else {
      return "You need more points!";
    }
  };

  return (
    <>
      <div className="py-5 flex items-center justify-center">
        <div className="relative h-[307px] w-[352px] sm:h-[613px] sm:w-[704px]">
          <div className="absolute w-full text-center -top-5">Points to win: {points[topic as keyof typeof points].total}</div>
          <img
            src={mapName[map]}
            alt="NFE Box"
            className="absolute top-1/2 -translate-y-1/2 left-1/2 -translate-x-1/2 pointer-events-none"
            width={704}
            height={704}
          />
          {coordinates.map((point) => (
            <GamePoint
              key={point.id.toString()}
              {...point}
              onPawnMoved={onPawnMoved}
            />
          ))}
          {teams.map((team) => (
            <GamePawn key={team.id.toString()} team={team} />
          ))}
          <div className="absolute text-sm left-1/2 -translate-x-1/2 -bottom-10">
            Multiple maps:{" "}
            <input
              type="checkbox"
              onChange={(e) => setMultipleMaps(Boolean(e.target.checked))}
            />
          </div>
          <button
            type="button"
            className="w-20 h-20 rounded-full absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2"
            onClick={() => setWinModal(true)}
          />
        </div>
      </div>
      <Modal open={winModal} setOpen={setWinModal}>
        <span className="text-xl text-center">{checkWinCondition()}</span>

        <Button
          className="bg-theme-olive w-full rounded h-10 mt-8 text-white"
          onClick={() => setWinModal(false)}
        >
          Okay
        </Button>
      </Modal>
    </>
  );
};

import { FC } from "react";

export const PawnSkateboardIcon: FC<{ height?: string; width?: string }> = ({
  height = "9",
  width = "12",
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 12 9"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.6824 4.73748L7.95693 3.23816M5.22062 7.52488L2.61597 6.04816"
        stroke="white"
        strokeWidth="1.5"
        strokeMiterlimit="10"
      />
      <path
        d="M3.96613 6.69363C4.25794 6.25248 4.72169 6.12967 5.07736 6.28098C5.62167 6.51187 5.70715 6.70346 5.70715 6.70346C5.56174 6.92354 4.63523 8.25485 4.63523 8.25485C4.63523 8.25485 4.2491 8.0839 4.02017 7.87757C3.73426 7.61917 3.67433 7.13478 3.96613 6.69363Z"
        fill="#979545"
      />
      <path
        d="M3.85229 6.90699C3.85229 6.90699 3.81103 7.16539 3.98886 7.25185C4.27772 7.39235 4.43591 7.11135 4.43591 7.11135C4.43591 7.11135 4.65108 6.76649 5.03622 6.62795C5.57759 6.43243 5.77606 6.80579 5.77606 6.80579C5.77606 6.80579 5.63261 6.36071 4.99398 6.25263C4.81418 6.22217 4.42117 6.35088 4.30228 6.41966C3.9191 6.64073 3.85229 6.90699 3.85229 6.90699Z"
        fill="#848404"
      />
      <path
        d="M5.65611 7.79534C5.948 7.35408 5.92341 6.82358 5.60118 6.61042C5.27895 6.39726 4.78111 6.58217 4.48921 7.02342C4.19732 7.46468 4.22191 7.99519 4.54414 8.20834C4.86637 8.4215 5.36422 8.23659 5.65611 7.79534Z"
        fill="#BCBB85"
      />
      <path
        d="M4.92907 7.58195C5.00668 7.48173 5.06957 7.41296 5.14129 7.51317C5.17175 7.56132 5.20122 7.63304 5.26017 7.64483C5.43899 7.68217 5.49401 7.44047 5.47731 7.31667C5.43408 7.02388 5.08332 7.05434 4.92415 7.30979C4.8318 7.44931 4.84162 7.63009 4.87797 7.71459C4.87797 7.71557 4.91335 7.60553 4.92907 7.58195Z"
        fill="#88863B"
      />
      <path
        d="M1.42048 5.50101C1.71229 5.05986 2.17603 4.93704 2.53171 5.08835C3.07602 5.31924 3.1615 5.51083 3.1615 5.51083C3.01609 5.73092 2.08957 7.06223 2.08957 7.06223C2.08957 7.06223 1.70344 6.89127 1.47452 6.68494C1.1886 6.42654 1.12867 5.94216 1.42048 5.50101Z"
        fill="#BCBB85"
      />
      <path
        d="M3.11094 6.60234C3.40284 6.16109 3.37825 5.63058 3.05602 5.41742C2.73379 5.20427 2.23594 5.38918 1.94405 5.83043C1.65215 6.27169 1.67675 6.80219 1.99898 7.01535C2.3212 7.22851 2.81905 7.0436 3.11094 6.60234Z"
        fill="#88863B"
      />
      <path
        d="M9.57839 3.92689C9.87118 3.48672 10.3359 3.36587 10.6916 3.51816C11.2349 3.75102 11.3204 3.94359 11.3204 3.94359C11.174 4.16367 10.2436 5.49106 10.2436 5.49106C10.2436 5.49106 9.85743 5.31911 9.62948 5.1118C9.34357 4.85144 9.2856 4.36706 9.57839 3.92689Z"
        fill="#979545"
      />
      <path
        d="M9.4641 4.14007C9.4641 4.14007 9.42185 4.39847 9.59969 4.48493C9.88855 4.62641 10.0477 4.34541 10.0477 4.34541C10.0477 4.34541 10.2933 3.96616 10.6834 3.83942C11.1815 3.6773 11.3898 4.04378 11.3898 4.04378C11.3898 4.04378 11.2444 3.67632 10.6461 3.51322C10.2688 3.41006 9.92392 3.5928 9.82861 3.66944C9.4808 3.94553 9.4641 4.14007 9.4641 4.14007Z"
        fill="#848404"
      />
      <path
        d="M11.2641 5.03243C11.5575 4.59215 11.5346 4.06157 11.2131 3.84734C10.8916 3.63311 10.3931 3.81636 10.0998 4.25664C9.80642 4.69692 9.82924 5.2275 10.1508 5.44173C10.4723 5.65596 10.9707 5.47271 11.2641 5.03243Z"
        fill="#BCBB85"
      />
      <path
        d="M10.54 4.83777C10.592 4.77784 10.6706 4.67762 10.7424 4.77882C10.7728 4.82697 10.8102 4.87511 10.8691 4.88297C11.0548 4.90852 11.1039 4.67959 11.0872 4.55481C11.045 4.26202 10.6942 4.2915 10.5341 4.54597C10.4417 4.6845 10.4506 4.86037 10.4859 4.95076C10.4859 4.95076 10.5066 4.87708 10.54 4.83777Z"
        fill="#88863B"
      />
      <path
        d="M11.8959 1.28788C11.55 0.285716 10.9242 0.159954 10.6785 0.0725098C10.6785 0.0725098 11.1187 0.447831 11.3201 0.903719C11.5795 1.49323 11.5648 2.23208 11.0018 2.63099L3.58968 6.09927C1.86242 6.52274 0.0339504 4.78958 0.0339504 4.78958C-0.0259831 5.1875 -0.168448 5.92733 1.30435 6.47558C2.6278 6.96782 3.94142 6.85778 4.50047 6.57776L11.2022 3.37573C11.8644 2.99746 12.1788 2.10632 11.8959 1.28788Z"
        fill="#BCBB85"
      />
      <path
        d="M11.6484 0.950907C11.3026 -0.0512608 10.1501 -0.0915439 9.49474 0.0745015C8.95043 0.213036 8.33537 0.720015 7.88243 1.10025C7.63877 1.30461 7.25362 1.48048 7.25362 1.48048L1.69454 4.14016C1.69454 4.14016 1.5334 4.20795 1.26223 4.21483C0.985158 4.22171 0.189319 4.17946 0.0596265 4.65303C-0.0563105 5.07846 0.12349 5.64341 1.41452 6.13074C2.736 6.62986 3.69396 6.5208 4.25399 6.24176L10.9557 3.03974C11.617 2.66147 11.9314 1.77033 11.6484 0.950907Z"
        fill="#68964D"
      />
      <path
        d="M3.64587 5.392C3.65799 5.28415 3.55067 5.18356 3.40616 5.16731C3.26164 5.15107 3.13466 5.22532 3.12254 5.33317C3.11041 5.44102 3.21774 5.54161 3.36225 5.55786C3.50677 5.5741 3.63375 5.49985 3.64587 5.392Z"
        fill="white"
      />
      <path
        d="M2.73498 4.84769C2.74711 4.73984 2.63978 4.63924 2.49527 4.623C2.35075 4.60675 2.22377 4.68101 2.21165 4.78886C2.19953 4.8967 2.30685 4.9973 2.45136 5.01355C2.59588 5.02979 2.72286 4.95553 2.73498 4.84769Z"
        fill="white"
      />
      <path
        d="M4.57727 4.95401C4.58939 4.84616 4.48207 4.74557 4.33755 4.72932C4.19304 4.71308 4.06606 4.78733 4.05393 4.89518C4.04181 5.00303 4.14913 5.10362 4.29365 5.11987C4.43816 5.13612 4.56514 5.06186 4.57727 4.95401Z"
        fill="white"
      />
      <path
        d="M3.66662 4.41092C3.67875 4.30307 3.57142 4.20248 3.42691 4.18623C3.28239 4.16999 3.15541 4.24424 3.14329 4.35209C3.13117 4.45994 3.23849 4.56053 3.383 4.57678C3.52752 4.59302 3.6545 4.51877 3.66662 4.41092Z"
        fill="white"
      />
      <path
        d="M8.70056 2.92545C8.71268 2.8176 8.60536 2.717 8.46084 2.70076C8.31633 2.68451 8.18935 2.75877 8.17723 2.86662C8.1651 2.97446 8.27243 3.07506 8.41694 3.0913C8.56145 3.10755 8.68843 3.03329 8.70056 2.92545Z"
        fill="white"
      />
      <path
        d="M7.78943 2.38223C7.80155 2.27439 7.69423 2.17379 7.54971 2.15755C7.4052 2.1413 7.27822 2.21556 7.26609 2.3234C7.25397 2.43125 7.36129 2.53185 7.50581 2.54809C7.65032 2.56434 7.7773 2.49008 7.78943 2.38223Z"
        fill="white"
      />
      <path
        d="M9.64685 2.496C9.65897 2.38816 9.55165 2.28756 9.40713 2.27131C9.26262 2.25507 9.13564 2.32933 9.12351 2.43717C9.11139 2.54502 9.21871 2.64562 9.36323 2.66186C9.50774 2.67811 9.63472 2.60385 9.64685 2.496Z"
        fill="white"
      />
      <path
        d="M8.73645 1.95279C8.74857 1.84494 8.64125 1.74435 8.49673 1.7281C8.35222 1.71186 8.22524 1.78611 8.21311 1.89396C8.20099 2.00181 8.30831 2.1024 8.45283 2.11865C8.59734 2.13489 8.72432 2.06064 8.73645 1.95279Z"
        fill="white"
      />
    </svg>
  );
};

import { FC } from "react";

export const ChipsIcon: FC<{ className?: string }> = ({
  className = "h-4 w-4 text-theme-dark-olive",
}) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M10.3806 9.623C10.2037 9.85466 9.96917 10.0456 9.69575 10.1806C9.42234 10.3155 9.1177 10.3907 8.80631 10.4C7.91618 10.4 7.14562 9.8 6.81348 8.9H8.65352C8.74161 8.9 8.82609 8.86839 8.88838 8.81213C8.95067 8.75587 8.98566 8.67957 8.98566 8.6C8.98566 8.52044 8.95067 8.44413 8.88838 8.38787C8.82609 8.33161 8.74161 8.3 8.65352 8.3H6.6773C6.6773 8.201 6.66069 8.099 6.66069 8C6.66069 7.901 6.66069 7.799 6.6773 7.7H8.65352C8.74161 7.7 8.82609 7.66839 8.88838 7.61213C8.95067 7.55587 8.98566 7.47957 8.98566 7.4C8.98566 7.32044 8.95067 7.24413 8.88838 7.18787C8.82609 7.13161 8.74161 7.1 8.65352 7.1H6.8168C7.14894 6.2 7.91618 5.6 8.80963 5.6C9.12046 5.6098 9.42443 5.68518 9.69723 5.8201C9.97002 5.95502 10.2041 6.14574 10.3806 6.377C10.4041 6.41346 10.4358 6.44504 10.4736 6.46969C10.5115 6.49435 10.5547 6.51152 10.6004 6.52008C10.6461 6.52865 10.6933 6.52842 10.7389 6.5194C10.7845 6.51039 10.8274 6.4928 10.865 6.46778C10.9026 6.44276 10.9339 6.41086 10.9569 6.37418C10.9799 6.3375 10.9941 6.29685 10.9985 6.25488C11.0029 6.21292 10.9975 6.17058 10.9826 6.13062C10.9677 6.09067 10.9437 6.054 10.9121 6.023C10.6755 5.71435 10.3613 5.46073 9.99531 5.28291C9.62928 5.10509 9.22182 5.00814 8.80631 5C7.55747 5 6.48134 5.852 6.12595 7.1H5.33214C5.24405 7.1 5.15957 7.13161 5.09728 7.18787C5.03499 7.24413 5 7.32044 5 7.4C5 7.47957 5.03499 7.55587 5.09728 7.61213C5.15957 7.66839 5.24405 7.7 5.33214 7.7H6.01302V8.3H5.33214C5.24405 8.3 5.15957 8.33161 5.09728 8.38787C5.03499 8.44413 5 8.52044 5 8.6C5 8.67957 5.03499 8.75587 5.09728 8.81213C5.15957 8.86839 5.24405 8.9 5.33214 8.9H6.12595C6.48798 10.148 7.55747 11 8.80631 11C9.22182 10.9919 9.62928 10.8949 9.99531 10.7171C10.3613 10.5393 10.6755 10.2856 10.9121 9.977C10.9532 9.9131 10.9667 9.83774 10.9501 9.76539C10.9334 9.69303 10.8878 9.62876 10.822 9.5849C10.7561 9.54104 10.6747 9.52067 10.5932 9.5277C10.5118 9.53473 10.4361 9.56867 10.3806 9.623Z"
        fill="currentColor"
      />
      <circle cx="8" cy="8" r="7.5" stroke="currentColor" />
    </svg>
  );
};

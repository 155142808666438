import { FC } from "react";

export const PawnScooterIcon: FC<{ height?: string; width?: string }> = ({
  height = "10",
  width = "10",
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 10 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g opacity="0.8">
        <path
          d="M1.25 9.99997C1.94036 9.99997 2.5 9.42531 2.5 8.71642C2.5 8.00753 1.94036 7.43286 1.25 7.43286C0.559644 7.43286 0 8.00753 0 8.71642C0 9.42531 0.559644 9.99997 1.25 9.99997Z"
          fill="#4D453C"
        />
        <path
          d="M1.25008 9.57225C1.71032 9.57225 2.08341 9.18914 2.08341 8.71654C2.08341 8.24395 1.71032 7.86084 1.25008 7.86084C0.789844 7.86084 0.416748 8.24395 0.416748 8.71654C0.416748 9.18914 0.789844 9.57225 1.25008 9.57225Z"
          fill="#88863B"
        />
        <path
          d="M8.75 9.99997C9.44036 9.99997 10 9.42531 10 8.71642C10 8.00753 9.44036 7.43286 8.75 7.43286C8.05964 7.43286 7.5 8.00753 7.5 8.71642C7.5 9.42531 8.05964 9.99997 8.75 9.99997Z"
          fill="#4D453C"
        />
        <path
          d="M8.75008 9.57225C9.21032 9.57225 9.58341 9.18914 9.58341 8.71654C9.58341 8.24395 9.21032 7.86084 8.75008 7.86084C8.28984 7.86084 7.91675 8.24395 7.91675 8.71654C7.91675 9.18914 8.28984 9.57225 8.75008 9.57225Z"
          fill="#88863B"
        />
        <path
          d="M8.75005 9.14428H3.1945C2.96533 9.14428 2.77783 8.95175 2.77783 8.71643C2.77783 8.48111 2.96533 8.28857 3.1945 8.28857H8.75005C8.97922 8.28857 9.16672 8.48111 9.16672 8.71643C9.16672 8.95175 8.97922 9.14428 8.75005 9.14428Z"
          fill="#68964D"
        />
        <path
          d="M7.22518 8.78678C7.22434 8.76339 7.22184 8.74029 7.22184 8.71661C7.22184 8.30054 7.38281 7.90152 7.66932 7.60731C7.95583 7.31311 8.34443 7.14782 8.74962 7.14782C9.08573 7.14782 9.39573 7.26078 9.64796 7.4496L9.89518 7.10589C9.56307 6.8551 9.16159 6.71988 8.74934 6.71997C8.23365 6.71997 7.73907 6.93033 7.37441 7.30477C7.00976 7.67922 6.8049 8.18707 6.8049 8.71661C6.8049 8.74571 6.80795 8.77395 6.80907 8.80275L7.22518 8.78678ZM1.56323 7.1812C1.90627 7.25508 2.21414 7.44783 2.4352 7.72712C2.65627 8.00642 2.77709 8.35528 2.7774 8.71519L3.74962 8.71604C3.74934 7.47812 2.89573 6.445 1.7599 6.20312C1.69545 6.52544 1.62768 6.86202 1.56323 7.1812Z"
          fill="#68964D"
        />
        <path
          d="M1.22077 8.85846C1.18478 8.85052 1.15333 8.82826 1.13328 8.79656C1.11324 8.76486 1.10624 8.7263 1.11382 8.68931L2.78105 0.634852C2.78878 0.597901 2.81045 0.565599 2.84132 0.545018C2.8722 0.524437 2.90975 0.517253 2.94577 0.525037C2.98175 0.532977 3.01321 0.555233 3.03325 0.586933C3.0533 0.618634 3.06029 0.657196 3.05271 0.694181L1.38549 8.74864C1.37776 8.78559 1.35608 8.81789 1.32521 8.83848C1.29434 8.85906 1.25679 8.86624 1.22077 8.85846Z"
          fill="#88863B"
        />
        <path
          d="M1.70075 7.22198L1.42909 7.16265C1.35711 7.14677 1.2942 7.10226 1.25412 7.03886C1.21403 6.97546 1.20004 6.89833 1.2152 6.82436L1.56159 5.15032C1.57705 5.07642 1.6204 5.01181 1.68214 4.97065C1.74389 4.92949 1.81899 4.91512 1.89103 4.93069L2.1627 4.99002C2.31214 5.02253 2.40853 5.17485 2.37659 5.32831L2.03047 7.00206C2.01487 7.07597 1.97147 7.14054 1.90971 7.18173C1.84795 7.22293 1.77285 7.23739 1.70075 7.22198Z"
          fill="#68964D"
        />
        <path
          d="M2.58331 0.964897L2.0397 0.84624C1.93178 0.822349 1.83745 0.755567 1.77734 0.660486C1.71722 0.565405 1.6962 0.449755 1.71886 0.338807C1.74213 0.227976 1.80716 0.131098 1.89974 0.0693219C1.99232 0.00754555 2.10495 -0.0141125 2.21303 0.00907564L2.75636 0.127733C2.86428 0.151624 2.9586 0.218406 3.01872 0.313487C3.07884 0.408568 3.09986 0.524218 3.0772 0.635166C3.05399 0.745975 2.98902 0.842851 2.89648 0.904632C2.80394 0.966413 2.69135 0.988079 2.58331 0.964897Z"
          fill="#BCBB85"
        />
        <path
          d="M3.0111 0.209388C2.90305 0.186036 2.79039 0.20759 2.69777 0.269333C2.60516 0.331076 2.54014 0.427979 2.51694 0.538834L2.14166 2.35236C2.12657 2.42632 2.1406 2.50341 2.18067 2.56679C2.22075 2.63017 2.28361 2.6747 2.35555 2.69064L2.62721 2.74997C2.69924 2.76546 2.77432 2.75106 2.83604 2.70991C2.89776 2.66876 2.94113 2.60421 2.95666 2.53034L3.33194 0.71682C3.3546 0.605872 3.33358 0.490223 3.27346 0.395142C3.21335 0.30006 3.11902 0.233278 3.0111 0.209388Z"
          fill="#68964D"
        />
        <path
          d="M3.91768 1.25606L3.10268 1.07807C2.99476 1.05418 2.90044 0.987402 2.84032 0.89232C2.78021 0.797239 2.75918 0.68159 2.78185 0.570642C2.80512 0.459825 2.87015 0.36297 2.96275 0.301239C3.05534 0.239509 3.16797 0.217922 3.27602 0.241196L4.09102 0.419182C4.19894 0.443073 4.29326 0.509855 4.35338 0.604936C4.41349 0.700017 4.43452 0.815667 4.41185 0.926615C4.38865 1.03747 4.32363 1.13437 4.23101 1.19612C4.1384 1.25786 4.02574 1.27941 3.91768 1.25606Z"
          fill="#BCBB85"
        />
        <path
          d="M4.41764 0.927874C4.40781 0.984327 4.38702 1.03817 4.35652 1.08621C4.32602 1.13425 4.28642 1.17551 4.24008 1.20752C4.19374 1.23953 4.14161 1.26166 4.08678 1.27257C4.03195 1.28348 3.97554 1.28297 3.92091 1.27105C3.86628 1.25914 3.81454 1.23606 3.76877 1.20321C3.72299 1.17035 3.68412 1.12838 3.65445 1.07979C3.62479 1.0312 3.60494 0.976979 3.59609 0.920354C3.58724 0.86373 3.58956 0.805857 3.60292 0.750173C3.6288 0.642318 3.69452 0.549026 3.78618 0.490025C3.87783 0.431025 3.98824 0.410941 4.09406 0.434021C4.19988 0.457101 4.2928 0.521535 4.35319 0.613698C4.41358 0.705862 4.43669 0.818528 4.41764 0.927874Z"
          fill="#88863B"
        />
        <path
          d="M9.77514 7.4899C9.88866 7.4899 9.98069 7.3954 9.98069 7.27882C9.98069 7.16225 9.88866 7.06775 9.77514 7.06775C9.66161 7.06775 9.56958 7.16225 9.56958 7.27882C9.56958 7.3954 9.66161 7.4899 9.77514 7.4899Z"
          fill="#BCBB85"
        />
      </g>
    </svg>
  );
};

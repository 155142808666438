import { IGamePoint } from "../components/game/GamePoint";

const shortestDistanceNode = (
  distances: { [key: number | string]: number | string },
  visited: Array<unknown>
) => {
  let shortest = null;

  for (let node in distances) {
    let currentIsShortest =
      shortest === null || distances[node] < distances[shortest];
    if (currentIsShortest && !visited.includes(Number(node))) {
      shortest = node;
    }
  }
  return Number(shortest);
};

export const findShortestPath = (
  graph: { [key: number]: IGamePoint },
  startNode: IGamePoint,
  endNode: IGamePoint
) => {
  // establish object for recording distances from the start node
  let distances: { [key: number]: number } = {};
  distances[endNode.id] = 1000000;
  distances = Object.assign(distances, graph[startNode.id].connects);

  // track nodes that have already been visited
  let visited: Array<unknown> = [];

  // find the nearest node
  let node = shortestDistanceNode(distances, visited);

  // for that node
  while (node > 0) {
    // find its distance from the start node & its child nodes
    let distance = distances[node];
    let children = graph[node].connects;
    // for each of those child nodes
    for (let child in children) {
      // make sure each child node is not the start node
      if (String(child) === String(startNode)) {
        continue;
      } else {
        // save the distance from the start node to the child node
        let newdistance = distance + children[child];
        // if there's no recorded distance from the start node to the child node in the distances object
        // or if the recorded distance is shorter than the previously stored distance from the start node to the child node
        // save the distance to the object
        // record the path
        if (!distances[child] || distances[child] > newdistance) {
          distances[child] = newdistance;
        }
      }
    }
    // move the node to the visited set
    visited.push(node);
    // move to the nearest neighbor node
    node = shortestDistanceNode(distances, visited);
  }

  return distances[endNode.id];
};

import create from "zustand";

export type TopicType =
  | ""
  | "Project Design"
  | "Values"
  | "Participation"
  | "Inclusion"
  | "Environment"
  | "Non Formal Learning"
  | "Debriefing";

interface TopicState {
  topic: TopicType;
  setTopic: (value: TopicType) => void;
}

export const useTopicStore = create<TopicState>()((set) => ({
  topic: "",
  setTopic: (value) => set((state) => ({ topic: value })),
}));

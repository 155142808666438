import create from "zustand";

export type LanguageType = "en" | "hr" | "nl" | "pt" | "si";

interface LanguageState {
  language: LanguageType;
  setLanguage: (value: LanguageType) => void;
}

export const useLanguageStore = create<LanguageState>()((set) => ({
  language: "en",
  setLanguage: (value) => set((state) => ({ language: value })),
}));

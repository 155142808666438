import { FC } from "react";

export const HeadsIcon: FC<{ className?: string }> = ({
  className = "h-4 w-4 text-theme-dark-olive",
}) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M7.99984 4.66663C8.44186 4.66663 8.86579 4.84222 9.17835 5.15478C9.49091 5.46734 9.6665 5.89127 9.6665 6.33329C9.6665 6.77532 9.49091 7.19924 9.17835 7.5118C8.86579 7.82436 8.44186 7.99996 7.99984 7.99996C7.55781 7.99996 7.13389 7.82436 6.82133 7.5118C6.50877 7.19924 6.33317 6.77532 6.33317 6.33329C6.33317 5.89127 6.50877 5.46734 6.82133 5.15478C7.13389 4.84222 7.55781 4.66663 7.99984 4.66663ZM7.99984 5.49996C7.77882 5.49996 7.56686 5.58776 7.41058 5.74404C7.2543 5.90032 7.1665 6.11228 7.1665 6.33329C7.1665 6.55431 7.2543 6.76627 7.41058 6.92255C7.56686 7.07883 7.77882 7.16663 7.99984 7.16663C8.22085 7.16663 8.43281 7.07883 8.58909 6.92255C8.74537 6.76627 8.83317 6.55431 8.83317 6.33329C8.83317 6.11228 8.74537 5.90032 8.58909 5.74404C8.43281 5.58776 8.22085 5.49996 7.99984 5.49996ZM7.99984 8.41663C9.11234 8.41663 11.3332 8.97079 11.3332 10.0833V11.3333H4.6665V10.0833C4.6665 8.97079 6.88734 8.41663 7.99984 8.41663ZM7.99984 9.20829C6.76234 9.20829 5.45817 9.81663 5.45817 10.0833V10.5416H10.5415V10.0833C10.5415 9.81663 9.23734 9.20829 7.99984 9.20829Z"
        fill="currentColor"
      />
      <circle cx="8" cy="8" r="7.5" stroke="currentColor" />
    </svg>
  );
};

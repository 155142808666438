import { FC, useEffect } from "react";
import { LanguageType, useLanguageStore } from "../store/language";
import { ThinDropdownMenu } from "./ThinDropdownMenu";

import { useTranslation } from "react-i18next";
import { Button } from "./Button";

const flagEmojiToPNG = (countryCode: string) => {
  return (
    <img
      src={`https://flagcdn.com/20x15/${countryCode}.png`}
      width="20"
      height="15"
      className="mr-1"
      alt="Flag"
    />
  );
};

const languageMap = {
  en: "gb",
  hr: "hr",
  nl: "nl",
  pt: "pt",
  si: "si",
};

const getKeyByValue = (object: any, value: string) => {
  return Object.keys(object).find((key) => object[key] === value);
};

export const Header: FC = () => {
  const { i18n } = useTranslation(["translation", "en"]);

  const language = useLanguageStore((state) => state.language);
  const setLanguage = useLanguageStore((state) => state.setLanguage);

  useEffect(() => {
    i18n.changeLanguage(language);
  }, [i18n, language]);

  return (
    <div className="w-full h-15 bg-theme-dark-olive flex justify-between">
      <div className="flex space-x-2 ml-2 items-center">
        <a
          href="https://drive.google.com/drive/folders/17KPILD6X2jygsif1b7Dlq2tLPGktRqgY"
          target="_blank"
          rel="noreferrer"
        >
          <Button className="bg-theme-olive w-fit rounded h-10 text-white px-2">
            Download materials
          </Button>
        </a>
        <a href="https://youtu.be/7iFwThoKbyk" target="_blank" rel="noreferrer">
          <Button className="bg-theme-olive w-fit rounded h-10 text-white px-2">
            Video tutorial
          </Button>
        </a>
      </div>
      <ThinDropdownMenu
        values={Object.values(languageMap).filter(
          (value) => value !== languageMap[language]
        )}
        selected={languageMap[language]}
        setSelected={(value) =>
          setLanguage(getKeyByValue(languageMap, value) as LanguageType)
        }
        transformLabel={(value) => (
          <span className="flex justify-center items-center">
            {flagEmojiToPNG(value)}|
            <span className="ml-1">
              {getKeyByValue(languageMap, value)?.toUpperCase()}
            </span>
          </span>
        )}
      />
    </div>
  );
};

import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import translationEN from "./locales/en/translation.json";
import translationHR from "./locales/hr/translation.json";
import translationNL from "./locales/nl/translation.json";
import translationPT from "./locales/pt/translation.json";
import translationSI from "./locales/si/translation.json";

const resources = {
  en: {
    translation: translationEN,
  },
  hr: {
    translation: translationHR,
  },
  nl: {
    translation: translationNL,
  },
  pt: {
    translation: translationPT,
  },
  si: {
    translation: translationSI,
  },
};

i18n.use(initReactI18next).init({
  resources,
  lng: "en",
  debug: true,
  interpolation: {
    escapeValue: false,
  },
});

export default i18n;

import { FC } from "react";
import { useDrag } from "react-dnd";
import { renderIcon } from "../../icons/pawns";
import { TeamType } from "../../store/teams";

export interface GamePawnProps {
  /**
   * Team ID
   */
  team: TeamType;
}

export const GamePawn: FC<GamePawnProps> = ({ team }) => {
  const [{ isDragging }, drag] = useDrag(() => ({
    type: "pawn",
    item: { teamId: team.id },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  }));

  return (
    <div
      className={`absolute w-5 h-5 z-10 flex items-center justify-center ${
        isDragging ? "cursor-grabbing" : "cursor-grab"
      }`}
      style={{
        left: team.coordinates.x + "px",
        top: team.coordinates.y + "px",
      }}
      ref={drag}
    >
      {renderIcon(team.pawn, true)}
    </div>
  );
};

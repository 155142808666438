import { FC, Fragment, useMemo } from "react";
import { Listbox, Transition } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/20/solid";
import { TeamType, useTeamsStore } from "../../store/teams";

import { renderIcon, iconNames } from "../../icons/pawns";

function classNames(...classes: any) {
  return classes.filter(Boolean).join(" ");
}

export type PawnDropdownMenuProps = {
  /**
   * Function that sets currently selected value
   */
  currentTeam: TeamType;
};

export const PawnDropdownMenu: FC<PawnDropdownMenuProps> = ({
  currentTeam,
}) => {
  const teams = useTeamsStore((state) => state.teams);
  const updateTeamProperty = useTeamsStore((state) => state.updateTeamProperty);

  const selectIcon = (e: string) => {
    updateTeamProperty({ id: currentTeam.id, key: "pawn", value: e });
  };

  const selectedIcons = useMemo(() => {
    return teams.map(({ pawn }) => pawn);
  }, [teams]);

  return (
    <Listbox value={currentTeam.pawn} onChange={selectIcon}>
      {({ open }) => (
        <div className="relative flex justify-center">
          <Listbox.Button className="relative h-10 cursor-default rounded-sm py-2 pl-3 pr-10 text-left transition border border-theme-dark-olive">
            <span className="block">{renderIcon(currentTeam.pawn)}</span>
            <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
              <ChevronDownIcon
                className={`h-5 w-5 transition text-theme-dark-olive ${
                  open ? "rotate-180" : "rotate-0"
                }`}
                aria-hidden="true"
              />
            </span>
          </Listbox.Button>

          <Transition
            show={open}
            as={Fragment}
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Listbox.Options className="absolute grid grid-cols-4 z-10 top-11 left-1/4 w-full rounded-sm bg-white border border-theme-dark-olive text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
              {Object.values(iconNames).map((value) => (
                <Listbox.Option
                  key={value}
                  className="relative cursor-default select-none text-center hover:bg-theme-dark-olive/25 w-8 h-8"
                  value={value}
                  disabled={selectedIcons.includes(value)}
                >
                  {({ selected, disabled }) => (
                    <span
                      className={classNames(
                        disabled && "bg-gray-300",
                        selected && "!bg-theme-sand",
                        "flex items-center justify-center w-full h-full"
                      )}
                    >
                      {renderIcon(value)}
                    </span>
                  )}
                </Listbox.Option>
              ))}
            </Listbox.Options>
          </Transition>
        </div>
      )}
    </Listbox>
  );
};

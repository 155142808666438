import { FC } from "react";

export const HeartsIcon: FC<{ className?: string }> = ({
  className = "h-4 w-4 text-theme-dark-olive",
}) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <circle cx="8" cy="8" r="7.5" stroke="currentColor" />
      <path
        d="M8.03 10.0845L8 10.1172L7.967 10.0845C6.542 8.6752 5.6 7.74332 5.6 6.79837C5.6 6.14441 6.05 5.65395 6.65 5.65395C7.112 5.65395 7.562 5.98093 7.721 6.42561H8.279C8.438 5.98093 8.888 5.65395 9.35 5.65395C9.95 5.65395 10.4 6.14441 10.4 6.79837C10.4 7.74332 9.458 8.6752 8.03 10.0845ZM9.35 5C8.828 5 8.327 5.26485 8 5.68011C7.673 5.26485 7.172 5 6.65 5C5.726 5 5 5.78801 5 6.79837C5 8.03106 6.02 9.04142 7.565 10.5684L8 11L8.435 10.5684C9.98 9.04142 11 8.03106 11 6.79837C11 5.78801 10.274 5 9.35 5Z"
        fill="currentColor"
      />
    </svg>
  );
};

import { IGamePoint } from "./components/game/GamePoint";
import { TopicType } from "./store/topic";

export const points = {
  Environment: {
    "0": { heads: 2, hearts: 0, hands: 1, chips: 6 },
    "1": { heads: 2, hearts: 0, hands: 2, chips: 10 },
    "2": { heads: 1, hearts: 1, hands: 1, chips: 6 },
    "3": { heads: 0, hearts: 1, hands: 2, chips: 10 },
    "4": { heads: 0, hearts: 1, hands: 2, chips: 9 },
    "5": { heads: 0, hearts: 2, hands: 1, chips: 7 },
    "6": { heads: 1, hearts: 4, hands: 1, chips: 8 },
    "7": { heads: 2, hearts: 0, hands: 0, chips: 9 },
    "8": { heads: 3, hearts: 0, hands: 0, chips: 7 },
    "9": { heads: 1, hearts: 1, hands: 3, chips: 8 },
    total: 80,
  },
  Participation: {
    "0": { heads: 2, hearts: 1, hands: 0, chips: 6 },
    "1": { heads: 1, hearts: 1, hands: 1, chips: 10 },
    "2": { heads: 1, hearts: 1, hands: 2, chips: 5 },
    "3": { heads: 0, hearts: 2, hands: 2, chips: 9 },
    "4": { heads: 2, hearts: 0, hands: 1, chips: 6 },
    "5": { heads: 2, hearts: 0, hands: 2, chips: 7 },
    "6": { heads: 0, hearts: 3, hands: 1, chips: 7 },
    "7": { heads: 4, hearts: 0, hands: 0, chips: 9 },
    "8": { heads: 0, hearts: 3, hands: 2, chips: 6 },
    "9": { heads: 1, hearts: 1, hands: 2, chips: 5 },
    total: 70,
  },
  "Project Design": {
    "0": { heads: 2, hearts: 2, hands: 0, chips: 7 },
    "1": { heads: 3, hearts: 0, hands: 0, chips: 5 },
    "2": { heads: 1, hearts: 2, hands: 1, chips: 6 },
    "3": { heads: 0, hearts: 1, hands: 3, chips: 9 },
    "4": { heads: 3, hearts: 0, hands: 1, chips: 6 },
    "5": { heads: 2, hearts: 1, hands: 0, chips: 6 },
    "6": { heads: 2, hearts: 1, hands: 2, chips: 8 },
    "7": { heads: 1, hearts: 2, hands: 1, chips: 10 },
    "8": { heads: 4, hearts: 0, hands: 0, chips: 8 },
    "9": { heads: 0, hearts: 0, hands: 0, chips: 5 },
    total: 70,
  },
  Inclusion: {
    "0": { heads: 1, hearts: 3, hands: 0, chips: 10 },
    "1": { heads: 2, hearts: 0, hands: 1, chips: 6 },
    "2": { heads: 0, hearts: 2, hands: 3, chips: 9 },
    "3": { heads: 1, hearts: 1, hands: 1, chips: 7 },
    "4": { heads: 2, hearts: 1, hands: 0, chips: 8 },
    "5": { heads: 0, hearts: 3, hands: 0, chips: 7 },
    "6": { heads: 3, hearts: 1, hands: 0, chips: 5 },
    "7": { heads: 3, hearts: 1, hands: 0, chips: 5 },
    "8": { heads: 0, hearts: 4, hands: 1, chips: 9 },
    "9": { heads: 2, hearts: 1, hands: 1, chips: 9 },
    total: 75,
  },
  Values: {
    "0": { heads: 0, hearts: 2, hands: 2, chips: 7 },
    "1": { heads: 1, hearts: 2, hands: 2, chips: 9 },
    "2": { heads: 0, hearts: 3, hands: 1, chips: 8 },
    "3": { heads: 0, hearts: 3, hands: 2, chips: 8 },
    "4": { heads: 1, hearts: 3, hands: 1, chips: 7 },
    "5": { heads: 2, hearts: 2, hands: 0, chips: 5 },
    "6": { heads: 2, hearts: 3, hands: 0, chips: 10 },
    "7": { heads: 0, hearts: 4, hands: 0, chips: 8 },
    "8": { heads: 3, hearts: 0, hands: 1, chips: 6 },
    "9": { heads: 2, hearts: 2, hands: 1, chips: 7 },
    total: 75,
  },
  "Non Formal Learning": {
    "0": { heads: 1, hearts: 1, hands: 1, chips: 5 },
    "1": { heads: 2, hearts: 0, hands: 2, chips: 7 },
    "2": { heads: 2, hearts: 1, hands: 1, chips: 5 },
    "3": { heads: 1, hearts: 2, hands: 2, chips: 6 },
    "4": { heads: 2, hearts: 0, hands: 2, chips: 9 },
    "5": { heads: 1, hearts: 1, hands: 3, chips: 8 },
    "6": { heads: 2, hearts: 1, hands: 0, chips: 7 },
    "7": { heads: 0, hearts: 3, hands: 0, chips: 6 },
    "8": { heads: 2, hearts: 2, hands: 1, chips: 8 },
    "9": { heads: 2, hearts: 2, hands: 2, chips: 9 },
    total: 70,
  },
  Debriefing: {
    "0": { heads: 0, hearts: 0, hands: 0, chips: 7 },
    "1": { heads: 0, hearts: 0, hands: 0, chips: 7 },
    "2": { heads: 0, hearts: 0, hands: 0, chips: 7 },
    "3": { heads: 0, hearts: 0, hands: 0, chips: 7 },
    "4": { heads: 0, hearts: 0, hands: 0, chips: 7 },
    "5": { heads: 0, hearts: 0, hands: 0, chips: 7 },
    "6": { heads: 0, hearts: 0, hands: 0, chips: 7 },
    "7": { heads: 0, hearts: 0, hands: 0, chips: 7 },
    "8": { heads: 0, hearts: 0, hands: 0, chips: 7 },
    "9": { heads: 0, hearts: 0, hands: 0, chips: 7 },
    total: 0,
  },
};

export const images: any = {
  Environment: {},
  Participation: {},
  "Project Design": {},
  Inclusion: {
    "1": "./dots.png",
    "6": "./cake.png",
  },
  Values: {},
  "Non Formal Learning": {},
  Debriefing: {},
};

export const searchableCoordinates: { [key: number]: IGamePoint } = {
  1: { id: 1, x: 153, y: -2, desc: "start-hex", connects: { 7: 1 } },
  2: { id: 2, x: 463, y: -2, desc: "start-hex", connects: { 19: 1 } },
  3: { id: 3, x: 0, y: 267, desc: "start-hex", connects: { 54: 1, 72: 1 } },
  4: { id: 4, x: 619, y: 267, desc: "start-hex", connects: { 34: 1 } },
  5: { id: 5, x: 153, y: 537, desc: "start-hex", connects: { 75: 1 } },
  6: { id: 6, x: 463, y: 537, desc: "start-hex", connects: { 65: 1 } },
  7: { id: 7, x: 233, y: 56, desc: "regular-point", connects: { 1: 1, 8: 1 } },
  8: {
    id: 8,
    x: 258,
    y: 85,
    desc: "regular-point",
    connects: { 7: 1, 9: 1, 10: 1 },
  },
  9: {
    id: 9,
    x: 277,
    y: 119,
    desc: "question-point",
    connects: { 8: 1, 10: 1, 11: 1, 12: 1 },
  },
  10: {
    id: 10,
    x: 292,
    y: 98,
    desc: "regular-point",
    connects: { 8: 1, 9: 1, 15: 1 },
  },
  11: {
    id: 11,
    x: 245,
    y: 148,
    desc: "regular-point",
    connects: { 12: 1, 44: 1, 48: 1 },
  },
  12: {
    id: 12,
    x: 291,
    y: 153,
    desc: "regular-point",
    connects: { 9: 1, 11: 1, 13: 1 },
  },
  13: {
    id: 13,
    x: 318,
    y: 159,
    desc: "regular-point",
    connects: { 12: 1, 40: 1 },
  },
  14: {
    id: 14,
    x: 353,
    y: 96,
    desc: "question-point",
    connects: { 15: 1, 16: 1 },
  },
  15: {
    id: 15,
    x: 323,
    y: 94,
    desc: "regular-point",
    connects: { 10: 1, 14: 1 },
  },
  16: {
    id: 16,
    x: 386,
    y: 110,
    desc: "regular-point",
    connects: { 14: 1, 17: 1 },
  },
  17: {
    id: 17,
    x: 419,
    y: 119,
    desc: "regular-point",
    connects: { 16: 1, 18: 1, 20: 1 },
  },
  18: {
    id: 18,
    x: 461,
    y: 113,
    desc: "regular-point",
    connects: { 17: 1, 19: 1, 20: 1 },
  },
  19: {
    id: 19,
    x: 475,
    y: 89,
    desc: "regular-point",
    connects: { 2: 1, 18: 1 },
  },
  20: {
    id: 20,
    x: 444,
    y: 138,
    desc: "question-point",
    connects: { 17: 1, 18: 1, 21: 1 },
  },
  21: {
    id: 21,
    x: 447,
    y: 167,
    desc: "regular-point",
    connects: { 20: 1, 22: 1 },
  },
  22: {
    id: 22,
    x: 432,
    y: 190,
    desc: "regular-point",
    connects: { 21: 1, 23: 1, 42: 1 },
  },
  23: {
    id: 23,
    x: 441,
    y: 223,
    desc: "regular-point",
    connects: { 24: 1, 30: 1, 42: 1 },
  },
  24: {
    id: 24,
    x: 482,
    y: 229,
    desc: "regular-point",
    connects: { 23: 1, 25: 1, 30: 1 },
  },
  25: {
    id: 25,
    x: 484,
    y: 201,
    desc: "regular-point",
    connects: { 24: 1, 26: 1 },
  },
  26: {
    id: 26,
    x: 491,
    y: 172,
    desc: "question-point",
    connects: { 25: 1, 27: 1 },
  },
  27: {
    id: 27,
    x: 518,
    y: 158,
    desc: "regular-point",
    connects: { 26: 1, 28: 1 },
  },
  28: {
    id: 28,
    x: 549,
    y: 154,
    desc: "regular-point",
    connects: { 27: 1, 29: 1 },
  },
  29: { id: 29, x: 577, y: 150, desc: "regular-point", connects: { 28: 1 } },
  30: {
    id: 30,
    x: 473,
    y: 269,
    desc: "question-point",
    connects: { 23: 1, 24: 1, 31: 1 },
  },
  31: {
    id: 31,
    x: 491,
    y: 303,
    desc: "regular-point",
    connects: { 30: 1, 35: 1 },
  },
  32: {
    id: 32,
    x: 539,
    y: 322,
    desc: "regular-point",
    connects: { 33: 1, 35: 1 },
  },
  33: {
    id: 33,
    x: 568,
    y: 313,
    desc: "regular-point",
    connects: { 32: 1, 34: 1 },
  },
  34: {
    id: 34,
    x: 600,
    y: 307,
    desc: "regular-point",
    connects: { 4: 1, 33: 1 },
  },
  35: {
    id: 35,
    x: 512,
    y: 339,
    desc: "regular-point",
    connects: { 31: 1, 32: 1, 36: 1 },
  },
  36: {
    id: 36,
    x: 482,
    y: 353,
    desc: "regular-point",
    connects: { 35: 1, 37: 1 },
  },
  37: {
    id: 37,
    x: 452,
    y: 361,
    desc: "question-point",
    connects: { 36: 1, 38: 1 },
  },
  38: {
    id: 38,
    x: 425,
    y: 351,
    desc: "regular-point",
    connects: { 37: 1, 39: 1 },
  },
  39: {
    id: 39,
    x: 403,
    y: 330,
    desc: "regular-point",
    connects: { 38: 1, 43: 1, 57: 1, 58: 1 },
  },
  40: {
    id: 40,
    x: 349,
    y: 166,
    desc: "regular-point",
    connects: { 13: 1, 41: 1, 42: 1 },
  },
  41: {
    id: 41,
    x: 366,
    y: 194,
    desc: "regular-point",
    connects: { 40: 1, 42: 1, 43: 1 },
  },
  42: {
    id: 42,
    x: 400,
    y: 193,
    desc: "question-point",
    connects: { 22: 1, 23: 1, 40: 1, 41: 1 },
  },
  43: {
    id: 43,
    x: 357,
    y: 223,
    desc: "regular-point",
    connects: { 39: 1, 41: 1, 57: 1, 58: 1 },
  },
  44: {
    id: 44,
    x: 200,
    y: 147,
    desc: "regular-point",
    connects: { 11: 1, 45: 1, 48: 1 },
  },
  45: {
    id: 45,
    x: 170,
    y: 146,
    desc: "regular-point",
    connects: { 44: 1, 46: 1 },
  },
  46: {
    id: 46,
    x: 141,
    y: 146,
    desc: "question-point",
    connects: { 45: 1, 47: 1 },
  },
  47: { id: 47, x: 111, y: 146, desc: "regular-point", connects: { 46: 1 } },
  48: {
    id: 48,
    x: 216,
    y: 167,
    desc: "regular-point",
    connects: { 11: 1, 44: 1, 49: 1 },
  },
  49: {
    id: 49,
    x: 203,
    y: 194,
    desc: "question-point",
    connects: { 48: 1, 50: 1 },
  },
  50: {
    id: 50,
    x: 194,
    y: 225,
    desc: "regular-point",
    connects: { 49: 1, 51: 1, 55: 1 },
  },
  51: {
    id: 51,
    x: 158,
    y: 243,
    desc: "regular-point",
    connects: { 50: 1, 52: 1 },
  },
  52: {
    id: 52,
    x: 127,
    y: 251,
    desc: "question-point",
    connects: { 51: 1, 53: 1 },
  },
  53: {
    id: 53,
    x: 100,
    y: 262,
    desc: "regular-point",
    connects: { 52: 1, 54: 1 },
  },
  54: {
    id: 54,
    x: 77,
    y: 275,
    desc: "regular-point",
    connects: { 3: 1, 53: 1 },
  },
  55: {
    id: 55,
    x: 230,
    y: 224,
    desc: "regular-point",
    connects: { 50: 1, 56: 1 },
  },
  56: {
    id: 56,
    x: 262,
    y: 236,
    desc: "regular-point",
    connects: { 55: 1, 57: 1 },
  },
  57: {
    id: 57,
    x: 284,
    y: 251,
    desc: "question-point",
    connects: { 39: 1, 43: 1, 56: 1, 58: 1 },
  },
  58: {
    id: 58,
    x: 342,
    y: 363,
    desc: "regular-point",
    connects: { 39: 1, 43: 1, 57: 1, 59: 1 },
  },
  59: {
    id: 59,
    x: 336,
    y: 393,
    desc: "regular-point",
    connects: { 58: 1, 60: 1, 66: 1 },
  },
  60: {
    id: 60,
    x: 367,
    y: 401,
    desc: "regular-point",
    connects: { 59: 1, 61: 1 },
  },
  61: {
    id: 61,
    x: 401,
    y: 403,
    desc: "question-point",
    connects: { 60: 1, 62: 1 },
  },
  62: {
    id: 62,
    x: 434,
    y: 412,
    desc: "regular-point",
    connects: { 61: 1, 63: 1 },
  },
  63: {
    id: 63,
    x: 465,
    y: 436,
    desc: "regular-point",
    connects: { 62: 1, 64: 1 },
  },
  64: {
    id: 64,
    x: 477,
    y: 472,
    desc: "regular-point",
    connects: { 63: 1, 65: 1 },
  },
  65: {
    id: 65,
    x: 476,
    y: 514,
    desc: "regular-point",
    connects: { 6: 1, 64: 1 },
  },
  66: {
    id: 66,
    x: 315,
    y: 416,
    desc: "regular-point",
    connects: { 59: 1, 67: 1 },
  },
  67: {
    id: 67,
    x: 286,
    y: 426,
    desc: "question-point",
    connects: { 66: 1, 68: 1, 73: 1 },
  },
  68: {
    id: 68,
    x: 246,
    y: 403,
    desc: "regular-point",
    connects: { 67: 1, 69: 1, 73: 1 },
  },
  69: {
    id: 69,
    x: 205,
    y: 393,
    desc: "regular-point",
    connects: { 68: 1, 70: 1 },
  },
  70: {
    id: 70,
    x: 166,
    y: 386,
    desc: "regular-point",
    connects: { 69: 1, 71: 1 },
  },
  71: {
    id: 71,
    x: 132,
    y: 360,
    desc: "regular-point",
    connects: { 70: 1, 72: 1 },
  },
  72: {
    id: 72,
    x: 104,
    y: 320,
    desc: "regular-point",
    connects: { 3: 1, 71: 1 },
  },
  73: {
    id: 73,
    x: 258,
    y: 445,
    desc: "regular-point",
    connects: { 67: 1, 68: 1, 74: 1 },
  },
  74: {
    id: 74,
    x: 240,
    y: 477,
    desc: "regular-point",
    connects: { 73: 1, 75: 1, 76: 1 },
  },
  75: {
    id: 75,
    x: 238,
    y: 511,
    desc: "regular-point",
    connects: { 5: 1, 74: 1 },
  },
  76: {
    id: 76,
    x: 277,
    y: 474,
    desc: "regular-point",
    connects: { 74: 1, 77: 1 },
  },
  77: {
    id: 77,
    x: 315,
    y: 478,
    desc: "regular-point",
    connects: { 76: 1, 78: 1 },
  },
  78: {
    id: 78,
    x: 324,
    y: 508,
    desc: "regular-point",
    connects: { 77: 1, 79: 1 },
  },
  79: {
    id: 79,
    x: 311,
    y: 543,
    desc: "question-point",
    connects: { 78: 1, 80: 1 },
  },
  80: { id: 80, x: 318, y: 578, desc: "regular-point", connects: { 79: 1 } },
};

export const coordinates: Array<IGamePoint> = Object.values(
  searchableCoordinates
);

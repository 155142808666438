import { ChangeEvent, FC, ReactNode } from "react";

export interface InputNumberProps {
  /**
   * HTML id attribute
   */
  id: string;
  /**
   * Label displayed above the input field that explains its' purpose
   */
  label: ReactNode;
  /**
   * Maximum possible value to assign to the input
   */
  max?: any;
  /**
   * Minimum possible value to assign to the input
   */
  min?: any;
  /**
   * Indicates if input field should be read only
   */
  readOnly?: boolean;
  /**
   * Number to increment by
   */
  step?: number;
  /**
   * Indicates if user has interacted with field (should be inherited from Formik)
   */
  touched?: boolean;
  /**
   * Input value
   */
  value: number;
  /**
   * Function to call when input value changes
   */
  handleChange?: (event: ChangeEvent<HTMLInputElement>) => void;
}

export const InputNumber: FC<InputNumberProps> = ({
  id,
  label,
  max = null,
  min = null,
  readOnly = false,
  touched = false,
  step = 1,
  value,
  handleChange = () => {},
}) => {
  return (
    <div className="mx-auto w-full max-w-sm flex justify-between items-center px-1">
      <label
        htmlFor={id}
        className="flex text-sm font-medium text-semibold text-gray-700 h-6 !leading-6 items-center"
      >
        {label}
      </label>
      <input
        id={id}
        name={id}
        type="number"
        readOnly={readOnly}
        value={value}
        min={min}
        max={max}
        step={step}
        className={`block h-6 rounded border p-1 shadow-lg focus:outline-none focus:ring-1 border-theme-olive hover:border-theme-dark-olive focus:ring-theme-olive sm:text-sm ${
          readOnly ? "hide-arrows text-right w-8" : "text-left w-12"
        }`}
        onChange={handleChange}
      />
    </div>
  );
};

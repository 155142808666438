import { FC } from "react";

export const PawnBalloonIcon: FC<{ height?: string; width?: string }> = ({
  height = "9",
  width = "12",
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 9 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.68269 1.31874C6.94666 0.582375 5.97806 0.124014 4.94195 0.021762C3.90584 -0.0804901 2.86633 0.179693 2.00056 0.757977C1.13478 1.33626 0.496316 2.19686 0.19395 3.19313C-0.108415 4.1894 -0.0559683 5.25969 0.342354 6.22162C0.740676 7.18356 1.46023 7.97761 2.37839 8.46848C3.29656 8.95934 4.35652 9.11665 5.37767 8.91358C6.39882 8.71052 7.31796 8.15966 7.97848 7.35486C8.63899 6.55006 9 5.54113 9 4.49999V4.48556C9.00069 3.8969 8.88462 3.31395 8.6585 2.77044C8.43239 2.22694 8.10072 1.7332 7.68269 1.31874ZM1.40048 4.49951C1.40048 2.34855 2.7375 0.560565 4.5024 0.178354C6.26731 0.560565 7.60432 2.35191 7.60432 4.49951C7.60432 6.6471 6.26731 8.43845 4.5024 8.82066C2.73558 8.43797 1.40048 6.65047 1.40048 4.49951Z"
        fill="#68964D"
      />
      <path
        d="M5.8822 4.49899L5.88268 4.41004C5.88268 2.87495 5.3649 1.46053 4.49422 0.332642L4.50576 0.348026C3.64663 1.4913 3.1298 2.93504 3.1298 4.49899C3.1298 6.06293 3.64663 7.50668 4.51874 8.66821L4.50576 8.65043C5.40179 7.48712 5.88611 6.05919 5.88268 4.59081L5.8822 4.49418V4.49899ZM5.51682 9.15043C5.46861 9.15051 5.42229 9.16921 5.38753 9.20262C5.35277 9.23604 5.33226 9.28158 5.33028 9.32975V9.83023H3.67643V9.33023C3.67469 9.28194 3.65427 9.2362 3.61947 9.20266C3.58468 9.16912 3.53822 9.1504 3.4899 9.15043H3.12643C3.06877 9.15054 3.01343 9.17316 2.9722 9.21349C2.93098 9.25381 2.90714 9.30864 2.90576 9.36629L3.06249 11.3245C3.06778 11.4447 3.16634 11.5403 3.28749 11.5403H5.72691C5.84711 11.5403 5.94518 11.4442 5.94759 11.3245L6.10432 9.36629C6.10306 9.3086 6.07926 9.2537 6.03801 9.21335C5.99676 9.173 5.94135 9.15041 5.88365 9.15043H5.51682Z"
        fill="#68964D"
      />
    </svg>
  );
};

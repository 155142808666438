import { FC, ReactNode } from "react";

export interface TeamsContainerProps {
  /**
   * Children
   */
  children?: ReactNode;
  /**
   * Indicates if this column is on the left side, and applies different horizontal margins
   */
  left?: boolean;
}

export const TeamsContainer: FC<TeamsContainerProps> = ({
  children,
  left = false,
}) => {
  const horizontalMargins = left ? "sm:mr-16" : "sm:ml-16";

  return (
    <div
      className={`my-4 mx-2 flex flex-row sm:flex-col sm:space-y-8 sm:space-x-0 space-y-0 space-x-8 space-between justify-between ${horizontalMargins}`}
    >
      {children}
    </div>
  );
};

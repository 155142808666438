import { FC } from "react";

export const PawnBoatIcon: FC<{ height?: string; width?: string }> = ({
  height = "13",
  width = "12",
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 12 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.58791 0H5.81598V2.2807H5.58791V0ZM11.4037 13H2.05282L1.59668 12.5439C1.59668 12.5439 5.40545 11.814 11.4037 13Z"
        fill="#88863B"
      />
      <path
        d="M5.93002 10.2631C5.93002 10.4 5.83879 10.4912 5.70195 10.4912C5.58791 10.4912 5.47388 10.4 5.47388 10.2631V1.59648C5.47388 1.45964 5.58791 1.36841 5.70195 1.36841C5.83879 1.36841 5.93002 1.45964 5.93002 1.59648V10.2631Z"
        fill="#68964D"
      />
      <path
        d="M10.7193 11.4034C10.7193 11.4034 9.67014 11.0385 8.43857 10.7192C7.66313 10.514 6.68243 10.3315 6.24909 10.2175C6.13506 10.1947 5.9526 10.0806 5.86137 10.0122L5.54207 9.73853C5.45085 9.6473 5.26839 9.60168 5.13155 9.60168H3.28418C3.17014 9.60168 2.98769 9.67011 2.89646 9.76133L2.62278 10.035C2.53155 10.1262 2.34909 10.1947 2.23506 10.1947H1.14032C1.02629 10.1947 0.935057 10.2859 0.957864 10.4227L1.414 12.2473C1.43681 12.3613 1.57365 12.4754 1.68769 12.4754H10.7193L10.9473 11.9052L10.7193 11.4034Z"
        fill="white"
      />
      <path
        d="M0 10.1492C0 10.1492 0.410526 11.1755 1.59649 12.5439C1.59649 12.5439 8.34737 13 11.4035 13L10.9474 11.8597C10.9474 11.8597 5.70175 10.1492 0 10.1492Z"
        fill="#68964D"
      />
      <path
        d="M5.13169 10.1491C5.13169 10.2175 5.08608 10.2631 5.01766 10.2631H4.78958C4.72116 10.2631 4.67555 10.2175 4.67555 10.1491V9.92104C4.67555 9.85262 4.72116 9.80701 4.78958 9.80701H5.01766C5.08608 9.80701 5.13169 9.85262 5.13169 9.92104V10.1491ZM4.21941 10.1491C4.21941 10.2175 4.1738 10.2631 4.10537 10.2631H3.42116C3.35274 10.2631 3.30713 10.2175 3.30713 10.1491V9.92104C3.30713 9.85262 3.35274 9.80701 3.42116 9.80701H4.10537C4.1738 9.80701 4.21941 9.85262 4.21941 9.92104V10.1491Z"
        fill="#3E4347"
      />
      <path
        d="M1.14049 8.96322C1.00365 9.07725 0.821195 9.2369 0.661546 9.41936C0.47909 9.57901 0.319441 9.76146 0.205405 9.89831C0.0913703 10.0351 0.0229492 10.1264 0.0229492 10.1264C0.0229492 10.1264 0.114177 10.058 0.25102 9.94392C0.387862 9.82988 0.570318 9.67024 0.729967 9.48778C0.912423 9.32813 1.07207 9.14567 1.18611 9.00883C1.30014 8.87199 1.36856 8.78076 1.36856 8.78076C1.36856 8.78076 1.27734 8.84918 1.14049 8.96322Z"
        fill="#68964D"
      />
      <path
        d="M5.4738 1.59644C5.4738 1.59644 2.28082 6.56837 0.684326 9.46486C0.684326 9.46486 2.85099 8.89468 5.4738 8.89468V1.59644Z"
        fill="#F9F3D9"
      />
      <path
        d="M5.92993 1.59644V8.89468L9.12291 9.57889L5.92993 1.59644Z"
        fill="#88863B"
      />
      <path
        d="M8.16492 11.8597H7.75439C7.75439 11.3579 8.16492 10.9474 8.66668 10.9474V11.3579C8.39299 11.3579 8.16492 11.586 8.16492 11.8597ZM8.66668 12.7719V12.3614C8.94036 12.3614 9.16843 12.1334 9.16843 11.8597H9.57896C9.57896 12.3614 9.16843 12.7719 8.66668 12.7719Z"
        fill="#BCBB85"
      />
      <path
        d="M8.66668 12.7719C8.16492 12.7719 7.75439 12.3614 7.75439 11.8597H8.16492C8.16492 12.1334 8.39299 12.3614 8.66668 12.3614V12.7719ZM9.57896 11.8597H9.16843C9.16843 11.586 8.94036 11.3579 8.66668 11.3579V10.9474C9.16843 10.9474 9.57896 11.3579 9.57896 11.8597Z"
        fill="white"
      />
      <path
        d="M5.81599 0.228027V1.1175C6.43178 1.23154 7.02476 0.547326 7.64055 0.661361C7.02476 0.250834 6.43178 0.638554 5.81599 0.228027ZM3.21599 5.17715C3.10195 5.38241 2.96511 5.56487 2.85107 5.77013C3.67213 5.86136 4.65283 6.11224 5.47388 6.61399V5.92978C5.47388 5.92978 4.76686 5.42803 3.21599 5.17715Z"
        fill="#BCBB85"
      />
      <path
        d="M2.21216 6.84219C4.76654 6.84219 5.47356 7.5264 5.47356 7.5264V6.61412C4.65251 6.08956 3.67181 5.86149 2.85075 5.77026C2.62268 6.15798 2.44023 6.43167 2.21216 6.84219Z"
        fill="#848404"
      />
      <path
        d="M8.55244 10.7192H8.78051V10.9473H8.55244V10.7192ZM8.55244 12.7719H8.78051V12.9999H8.55244V12.7719ZM9.57875 11.7456H9.80682V11.9736H9.57875V11.7456ZM7.52612 11.7456H7.75419V11.9736H7.52612V11.7456Z"
        fill="white"
      />
      <path
        d="M7.70862 11.7457H7.59458C7.59458 11.7229 7.61739 11.2896 7.86827 11.0387C8.14195 10.765 8.55248 10.7878 8.57529 10.7878V10.9019C8.57529 10.9019 8.18757 10.879 7.9595 11.1071C7.70862 11.3352 7.70862 11.7457 7.70862 11.7457ZM9.62441 11.7457C9.62441 11.7457 9.6016 11.3352 9.39634 11.1071C9.16827 10.879 8.78055 10.9019 8.78055 10.9019V10.7878C8.80336 10.7878 9.21388 10.765 9.48757 11.0387C9.73844 11.2896 9.76125 11.7457 9.76125 11.7457H9.62441ZM8.52967 12.9545C8.43844 12.9545 8.09634 12.9317 7.84546 12.7036C7.59458 12.4527 7.57178 11.9966 7.57178 11.9738H7.68581C7.68581 11.9738 7.70862 12.3843 7.91388 12.6124C8.14195 12.8405 8.52967 12.8405 8.52967 12.8176V12.9545C8.55248 12.9545 8.55248 12.9545 8.52967 12.9545ZM8.80336 12.9545C8.78055 12.9545 8.78055 12.9545 8.80336 12.9545L8.78055 12.8405C8.78055 12.8405 9.16827 12.8633 9.39634 12.6352C9.62441 12.4071 9.62441 11.9966 9.62441 11.9966H9.73844C9.73844 12.0194 9.71564 12.4527 9.46476 12.7264C9.23669 12.9317 8.89458 12.9545 8.80336 12.9545Z"
        fill="#88863B"
      />
      <path
        d="M1.36823 11.2894C1.55717 11.2894 1.71033 11.1363 1.71033 10.9473C1.71033 10.7584 1.55717 10.6052 1.36823 10.6052C1.17929 10.6052 1.02612 10.7584 1.02612 10.9473C1.02612 11.1363 1.17929 11.2894 1.36823 11.2894Z"
        fill="#3E4347"
      />
    </svg>
  );
};

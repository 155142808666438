import { FC } from "react";

export const PawnSnowboardIcon: FC<{ height?: string; width?: string }> = ({
  height = "12",
  width = "10",
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 10 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.80012 7.94697C8.59356 7.56981 8.05404 7.49171 7.65222 7.63353C7.29356 7.76096 6.95752 7.9891 6.71191 8.15661C6.5742 8.25115 6.45396 8.33234 6.37586 8.36523L3.11405 9.72894C2.89413 9.79265 2.64132 9.82451 2.37413 9.85842C2.10591 9.89234 1.82844 9.92728 1.58385 9.99819C1.03097 10.1554 0.801799 10.4483 0.793578 11.0084C0.789467 11.2725 0.890179 11.5212 1.07516 11.7093C1.26014 11.8963 1.50678 12.0001 1.76986 12.0001C2.20765 12.0001 2.50156 11.7884 2.60638 11.6979C2.61152 11.6938 3.09041 11.3105 3.45626 11.1451L7.02637 9.65186C7.18772 9.58404 7.43538 9.53471 7.69744 9.4823C7.78993 9.4638 7.88344 9.4453 7.97491 9.42475C8.50724 9.30759 8.83815 9.09795 8.9553 8.79993C9.1002 8.43408 8.87514 8.05693 8.80012 7.94697Z"
        fill="url(#paint0_linear_424_711)"
      />
      <path
        d="M6.7511 6.32528H6.75007C6.74802 6.315 6.74699 6.3037 6.74391 6.29342C6.72002 6.19165 6.67327 6.09666 6.60722 6.01565C6.54116 5.93463 6.45752 5.86972 6.36264 5.82583L6.36367 5.82378L4.55498 5.01603L4.54984 5.02734C4.54676 5.02631 4.54367 5.02426 4.54059 5.0222C4.45118 4.98212 4.35561 4.96362 4.26209 4.96362C3.99901 4.96362 3.74826 5.11572 3.63419 5.37058C3.61775 5.40655 3.60644 5.44354 3.59719 5.47951C3.37419 5.28837 3.05047 5.14038 2.78225 5.14038C2.68771 5.14038 2.60036 5.15888 2.52637 5.19999C2.15641 5.40552 2.26637 6.10125 2.47087 6.47018C2.47293 6.47326 2.47498 6.47737 2.47704 6.48046L2.47601 6.48149L3.17174 7.73627C3.23545 7.85136 3.24881 7.98702 3.20874 8.11136L2.69285 9.7248L2.79253 9.97863L4.09767 9.96836L4.72763 7.93563C4.73585 7.91508 4.74407 7.89453 4.75023 7.87295C4.7564 7.85136 4.76154 7.82978 4.76668 7.8082L4.76976 7.79793C4.81703 7.56876 4.73688 7.37144 4.61972 7.16283L4.17782 6.36741L5.30312 6.86994C5.35538 6.89307 5.40153 6.92804 5.43792 6.9721C5.47431 7.01616 5.49993 7.06809 5.51276 7.12378L5.87656 8.68172L7.22691 8.36623L6.7511 6.32528Z"
        fill="#BCBB85"
      />
      <path
        d="M7.49715 3.48477C7.49282 3.47772 7.48678 3.47186 7.4796 3.46773C7.47242 3.4636 7.46432 3.46133 7.45604 3.46114L6.15707 3.39742C6.02759 3.34604 4.65668 2.81165 4.40799 2.80343C4.38024 2.79932 4.36277 2.81782 4.3566 2.84145L4.04317 3.98525C4.037 4.00991 4.0483 4.0356 4.07194 4.04588C4.12332 4.06952 5.34727 4.61418 5.7717 4.70667C5.83233 4.72003 5.90632 4.72619 5.98956 4.72619C6.50648 4.72619 7.3543 4.49908 7.39541 4.48778C7.40466 4.48572 7.41288 4.48058 7.42008 4.47339C7.83114 4.02944 7.50126 3.49094 7.49715 3.48477Z"
        fill="url(#paint1_linear_424_711)"
      />
      <path
        d="M4.9311 4.20316C4.88999 3.96474 4.84889 3.71913 4.82217 3.47969C4.81395 3.25874 4.73071 3.08198 4.58067 2.96894C4.43782 2.86103 4.24256 2.81068 4.00004 2.81993L3.08644 2.85384C2.66201 2.85692 2.23451 3.25566 2.25095 3.70988L2.26225 5.63676C2.27664 6.0098 2.38352 6.27494 2.58083 6.42703C2.70312 6.52158 2.8583 6.56782 3.04533 6.56782C3.14296 6.56782 3.24984 6.55446 3.36391 6.52877C4.45529 6.28008 5.06264 5.93992 5.07292 5.57099C5.08525 5.10237 5.00715 4.64506 4.9311 4.20316Z"
        fill="url(#paint2_linear_424_711)"
      />
      <path
        d="M8.0663 3.49909C8.06013 3.49704 7.38598 3.28739 7.13832 3.83822C7.13729 3.84131 7.13523 3.84542 7.13523 3.8485L7.13009 3.87214C7.06227 4.17324 7.24828 4.35206 7.36851 4.40961C7.48567 4.46613 7.77752 4.45688 7.8361 4.4538C7.88029 4.46407 7.92345 4.4764 7.96353 4.48771C8.04266 4.51032 8.12385 4.53293 8.19887 4.53293C8.30472 4.53293 8.40132 4.48771 8.46812 4.33048L8.4712 4.32123L8.47634 4.29759C8.54211 4.00779 8.4229 3.64091 8.0663 3.49909ZM1.3207 5.93363L1.23335 5.84834C1.02987 5.64486 0.543782 5.91 0.441015 5.9696C0.310502 5.96549 0.0772214 6.02304 0.0186444 6.26865C-0.0255452 6.45363 0.00939555 6.616 0.1173 6.73727C0.234454 6.86778 0.430739 6.9428 0.646549 6.9428C0.684572 6.9428 0.722596 6.94075 0.761647 6.93561C1.4286 6.85237 1.36283 6.23063 1.33508 5.96446C1.33398 5.95284 1.3289 5.94195 1.3207 5.93363Z"
        fill="#848404"
      />
      <path
        d="M3.15109 3.71706C3.15109 3.70062 3.15109 3.68521 3.15006 3.66876C3.12745 3.37999 2.91678 3.13643 2.63109 3.08916C2.53449 3.07272 2.442 3.07991 2.35567 3.10252C2.34745 3.10252 2.3382 3.10047 2.32895 3.10047C2.24777 3.10047 2.16967 3.11691 2.10184 3.1539C1.51607 3.37177 0.479156 3.7705 0.412358 4.34908C0.356864 4.83722 0.395915 6.05501 0.395915 6.05501C0.55212 6.17319 0.708326 6.21327 0.849116 6.21327C1.14817 6.21327 1.37528 6.0324 1.37528 6.0324L1.39686 5.93682L1.39789 5.9358C1.39789 5.9358 1.39994 5.92757 1.402 5.91421L1.68153 4.6841C1.68153 4.6841 2.5201 4.3943 2.74516 4.31414C2.93219 4.24528 3.17267 3.9822 3.15109 3.71706Z"
        fill="url(#paint3_linear_424_711)"
      />
      <path
        d="M5.00799 0.577548C4.68222 0.237391 4.15606 0 3.62475 0C3.47883 0 3.33187 0.0174703 3.19005 0.0565216C2.43883 0.261027 2.22816 0.622765 2.14697 0.801579C2.10689 0.888931 2.09148 0.989642 2.0329 1.06261C1.95377 1.16126 1.81812 1.23012 1.73282 1.33186C1.69994 1.37091 1.66808 1.42229 1.68863 1.46853C1.70713 1.51067 1.75954 1.523 1.80579 1.53019C2.32065 1.60419 3.72444 1.49525 3.9793 1.69976C4.12009 1.8128 4.04301 2.41707 4.04712 2.62671C4.05124 2.82197 4.0574 2.79833 4.23313 2.88363C4.29171 2.91138 4.34412 2.92474 4.39139 2.92474C4.61953 2.92474 4.74285 2.63905 4.91961 2.42118C5.33273 1.91146 5.59479 1.1849 5.00799 0.577548Z"
        fill="url(#paint4_linear_424_711)"
      />
      <path
        d="M4.17245 9.48635L2.80463 9.67236C2.73166 9.68366 2.67822 9.74635 2.67822 9.82034V10.0002C2.67822 10.1194 2.75016 10.2263 2.86115 10.2705C3.05024 10.3455 3.34518 10.4554 3.54557 10.4986C3.61545 10.514 3.68431 10.5202 3.75213 10.5202C4.05118 10.5202 4.32352 10.3958 4.44375 10.3311C4.48486 10.3085 4.51363 10.2684 4.51877 10.2222C4.5568 9.87275 4.52288 9.53362 4.17245 9.48635ZM7.13829 8.17505L5.80438 8.396L5.82391 8.77932C5.82905 8.87797 5.90304 8.95813 6.00067 8.97046C6.26786 9.00232 6.78375 9.06192 7.01806 9.07426C7.0458 9.07528 7.07355 9.07631 7.10027 9.07631C7.44865 9.07631 7.68296 8.96327 7.78469 8.90058C7.82477 8.87592 7.84841 8.83173 7.84738 8.78445C7.84019 8.45149 7.48667 8.22232 7.13829 8.17505Z"
        fill="#848404"
      />
      <path
        d="M4.11824 1.93207L4.01856 1.72037C3.97745 1.58677 3.44409 1.58472 3.44409 1.58472L3.50061 2.821L3.50575 2.92479L3.50986 3.0255C3.50986 3.0255 3.89935 2.96179 4.0535 2.87649C4.07405 2.86416 4.09049 2.85286 4.10077 2.84052L4.11927 2.75728L4.18709 2.45515L4.96812 2.28661C4.9897 2.264 5.01128 2.24037 5.03595 2.2157C5.04828 2.20132 5.06267 2.18693 5.07705 2.17254C5.15927 2.0893 5.21065 2.02045 5.25073 1.95776C5.26306 1.93926 5.27436 1.92179 5.28464 1.90432L4.11824 1.93207Z"
        fill="#3E4347"
      />
      <path
        d="M6.01527 2.27732C5.40381 2.64933 5.48294 2.9155 4.92492 3.39439C4.4265 3.8219 3.86128 4.03257 3.11623 3.71399C1.89844 3.19194 2.07931 2.59692 2.07931 2.59692C2.07931 2.59692 2.3722 2.99565 3.5684 2.94222C4.41828 2.90419 4.60531 2.64933 4.95164 2.30404C5.29796 1.95874 5.05851 1.71929 5.80254 1.34625C6.5476 0.974235 7.34507 1.08008 7.34507 1.08008L8.32855 1.90427C8.32855 1.90427 6.62673 1.90427 6.01527 2.27732Z"
        fill="#BCBB85"
      />
      <path
        d="M2.56018 2.91034C2.53243 2.86307 2.49338 2.79832 2.44508 2.71508L2.30737 2.7048C2.29196 2.76338 2.22207 2.95556 2.16966 2.98536C2.13678 3.00386 2.0669 2.91034 2.01654 2.83326C1.97852 2.77366 1.97132 2.70069 1.99701 2.6339C2.00832 2.60409 2.02065 2.57326 2.02887 2.55271C2.05148 2.49311 2.08539 2.43967 2.10595 2.38006C2.12753 2.31737 2.0895 2.24749 2.07203 2.18481C2.05148 2.11492 2.03093 2.04607 2.00935 1.97516C2.00421 1.95666 1.9446 1.71208 1.93124 1.71208C1.93124 1.71208 1.87575 1.71105 1.86753 1.64323C1.86239 1.60212 1.86034 1.52813 1.97646 1.5271L3.22405 1.5569C3.39053 1.56101 3.56832 1.5641 3.705 1.80868C3.80879 1.99572 3.76255 2.7305 3.71322 2.8271C3.68958 2.87437 3.65567 2.97097 3.50974 3.02338C3.40081 3.06141 2.96508 3.03777 2.74824 3.02235C2.67014 3.0203 2.60128 2.97816 2.56018 2.91034Z"
        fill="#E3F2FD"
      />
      <path
        d="M2.96711 2.7551C2.8944 2.74606 2.82533 2.71816 2.76674 2.67417C2.70815 2.63018 2.66209 2.57164 2.63311 2.50435C2.57454 2.36973 2.50466 2.21455 2.47794 2.19194C2.42964 2.14981 2.24157 2.157 2.21896 2.20016C2.19636 2.24435 2.13058 2.54546 2.07201 2.6472C1.83976 2.13336 1.91272 1.72641 1.91272 1.72641C1.91272 1.72641 2.16964 1.72538 2.2611 1.72641C2.4245 1.72846 2.5879 1.73155 2.75129 1.73463C3.01129 1.73977 3.27129 1.68941 3.38125 1.98949C3.46861 2.22894 3.41003 2.71605 3.31651 2.74585C3.22813 2.77771 3.08837 2.77052 2.96711 2.7551Z"
        fill="url(#paint5_linear_424_711)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_424_711"
          x1="0.792448"
          y1="9.78361"
          x2="9.00288"
          y2="9.78361"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.171" stopColor="#68964D" />
          <stop offset="0.62" stopColor="#68964D" />
          <stop offset="0.985" stopColor="#68964D" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_424_711"
          x1="4.04214"
          y1="3.7643"
          x2="7.62612"
          y2="3.7643"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.034" stopColor="#68964D" />
          <stop offset="0.326" stopColor="#BCBB85" />
          <stop offset="0.46" stopColor="#68964D" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_424_711"
          x1="3.66234"
          y1="3.57433"
          x2="3.66234"
          y2="6.34903"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.189" stopColor="#68964D" />
          <stop offset="0.569" stopColor="#BCBB85" />
          <stop offset="0.796" stopColor="#68964D" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_424_711"
          x1="0.806776"
          y1="6.1514"
          x2="2.03278"
          y2="3.53445"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.189" stopColor="#68964D" />
          <stop offset="0.326" stopColor="#68964D" />
          <stop offset="0.46" stopColor="#BCBB85" />
          <stop offset="1" stopColor="#68964D" />
        </linearGradient>
        <linearGradient
          id="paint4_linear_424_711"
          x1="3.11236"
          y1="0.558947"
          x2="4.25954"
          y2="2.59516"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.176" stopColor="#68964D" />
          <stop offset="0.623" stopColor="#BCBB85" />
          <stop offset="0.985" stopColor="#68964D" />
        </linearGradient>
        <linearGradient
          id="paint5_linear_424_711"
          x1="1.86784"
          y1="2.12489"
          x2="3.43234"
          y2="2.29305"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.365" stopColor="#848404" />
          <stop offset="0.987" stopColor="#BCBB85" />
        </linearGradient>
      </defs>
    </svg>
  );
};

import { FC } from "react";
import { InputGeneric, InputNumber, PawnDropdownMenu } from "..";
import { ChipsIcon, HandsIcon, HeadsIcon, HeartsIcon } from "../../icons";

import { TeamType, useTeamsStore } from "../../store/teams";
import { useMultipleMapsStore } from "../../store/multipleMaps";

export interface TeamComponentProps {
  /**
   * Team to display in component
   */
  team: TeamType;
}

export const TeamComponent: FC<TeamComponentProps> = ({ team }) => {
  const multipleMaps = useMultipleMapsStore((state) => state.multipleMaps);
  const updateTeamProperty = useTeamsStore((state) => state.updateTeamProperty);

  const updateTeam = (key: keyof TeamType, value: number | string) => {
    updateTeamProperty({ id: team.id, key, value });
  };

  return (
    <>
      <div className="w-full max-w-[200px] mx-auto space-y-1 border border-theme-sand rounded-sm p-6 relative">
        <InputGeneric
          id="1"
          placeholder="Team 1"
          value={team.name}
          handleChange={(e) => updateTeam("name", e.target.value)}
        />

        <InputNumber
          id="2"
          label={<ChipsIcon className="h-5 w-5 text-theme-dark-olive" />}
          value={team.chips}
          handleChange={(e) => updateTeam("chips", Number(e.target.value))}
          readOnly={!multipleMaps}
        />

        <InputNumber
          id="3"
          label={<HeadsIcon className="h-5 w-5 text-theme-dark-olive" />}
          value={team.heads}
          handleChange={(e) => updateTeam("heads", Number(e.target.value))}
          readOnly={!multipleMaps}
        />

        <InputNumber
          id="4"
          label={<HeartsIcon className="h-5 w-5 text-theme-dark-olive" />}
          value={team.hearts}
          handleChange={(e) => updateTeam("hearts", Number(e.target.value))}
          readOnly={!multipleMaps}
        />

        <InputNumber
          id="5"
          label={<HandsIcon className="h-5 w-5 text-theme-dark-olive" />}
          value={team!.hands}
          handleChange={(e) => updateTeam("hands", Number(e.target.value))}
          readOnly={!multipleMaps}
        />

        <PawnDropdownMenu currentTeam={team} />
      </div>
    </>
  );
};

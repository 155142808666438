import { FC, Fragment, ReactNode } from "react";
import { Listbox, Transition } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/20/solid";

function classNames(...classes: any) {
  return classes.filter(Boolean).join(" ");
}

export type ThinDropdownMenuProps = {
  /**
   * Array of values to be displayed inside the dropdown
   */
  values: Array<string>;
  /**
   * Currently selected value
   */
  selected: string;
  /**
   * Function that sets currently selected value
   */
  setSelected: (value: string) => void;
  /**
   * Function that sets transforms the label into a component
   */
  transformLabel?: (value: string) => ReactNode;
};

export const ThinDropdownMenu: FC<ThinDropdownMenuProps> = ({
  values,
  selected,
  setSelected,
  transformLabel = null,
}) => {
  return (
    <Listbox value={selected} onChange={setSelected}>
      {({ open }) => (
        <div className="flex flex-col items-center text-white w-[8.25rem]">
          <div className="relative mt-3">
            <Listbox.Button className="relative h-10 cursor-default rounded-sm py-2 pl-3 pr-10 text-left transition">
              <span className="block">
                {transformLabel ? transformLabel(selected) : selected}
              </span>
              <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                <ChevronDownIcon
                  className={`h-5 w-5 transition ${
                    open ? "rotate-180" : "rotate-0"
                  }`}
                  aria-hidden="true"
                />
              </span>
            </Listbox.Button>

            <Transition
              show={open}
              as={Fragment}
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Listbox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-hidden rounded-sm bg-theme-olive py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                {values
                  .filter((value) => value)
                  .map((value, index) => (
                    <Listbox.Option
                      key={`option-${index}`}
                      className="relative cursor-default select-none p-2 text-center hover:bg-theme-dark-olive/25"
                      value={value}
                    >
                      {({ selected }) => (
                        <span
                          className={classNames(
                            selected ? "font-semibold" : "font-normal",
                            "block w-full"
                          )}
                        >
                          {transformLabel ? transformLabel(value) : value}
                        </span>
                      )}
                    </Listbox.Option>
                  ))}
              </Listbox.Options>
            </Transition>
          </div>
        </div>
      )}
    </Listbox>
  );
};

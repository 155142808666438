import React from "react";
import ReactDOM from "react-dom/client";
import "./index.scss";

import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { Main, Map } from "./pages";
import { Header } from "./components";

import { DndProvider } from "react-dnd";
// import { TouchBackend } from "react-dnd-touch-backend";
import { HTML5Backend } from "react-dnd-html5-backend";

import "./i18n";

import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

const router = createBrowserRouter([
  {
    path: "/",
    element: <Main />,
  },
  {
    path: "/map",
    element: <Map />,
  },
]);

root.render(
  <React.StrictMode>
    <Header />
    <DndProvider backend={HTML5Backend} options={{ enableMouseEvents: true }}>
      <div className="h-[calc(100%-3.75rem)] overflow-auto">
        <RouterProvider router={router} />
      </div>
    </DndProvider>
    <ToastContainer />
  </React.StrictMode>
);

import create from "zustand";

export type MapType =
  | ""
  | "Solin"
  | "Split"
  | "Zreče"
  | "Vila de Marmeleira"
  | "Antwerp";

interface MapState {
  map: MapType;
  setMap: (value: MapType) => void;
}

export const useMapStore = create<MapState>()((set) => ({
  map: "",
  setMap: (value) => set((state) => ({ map: value })),
}));

import { FC, useEffect, useState } from "react";
import { Button, DropdownMenu, Modal } from "../components";
import { useNavigate } from "react-router-dom";

import { useNoOfTeamStore } from "../store/noOfTeams";
import { MapType, useMapStore } from "../store/map";
import { TopicType, useTopicStore } from "../store/topic";
import { UserIcon } from "@heroicons/react/24/outline";

const topicsValues: Array<string> = [
  "",
  "Project Design",
  "Values",
  "Participation",
  "Inclusion",
  "Environment",
  "Non Formal Learning",
  "Debriefing",
];

const mapsValues: Array<string> = [
  "",
  "Solin",
  "Split",
  "Zreče",
  "Vila de Marmeleira",
  "Antwerp",
];

const teamsValues: Array<string> = ["", "1", "2", "3", "4", "5", "6"];

export const Main: FC = () => {
  const [firstLoad, setFirstLoad] = useState<boolean>(false);
  const [contactModal, setContactModal] = useState<boolean>(false);
  const noOfTeams = useNoOfTeamStore((state) => state.noOfTeams);
  const setNoOfTeams = useNoOfTeamStore((state) => state.setNoOfTeams);

  const map = useMapStore((state) => state.map);
  const setMap = useMapStore((state) => state.setMap);

  const topic = useTopicStore((state) => state.topic);
  const setTopic = useTopicStore((state) => state.setTopic);

  const navigate = useNavigate();

  useEffect(() => {
    setMap("");
    setTopic("");
    setNoOfTeams(0);
    setFirstLoad(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (topic === "Project Design") {
      setMap("Split");
    }
  }, [setMap, topic]);

  useEffect(() => {
    if (firstLoad && map && noOfTeams && topic) {
      navigate("/map");
    }
  }, [firstLoad, map, navigate, noOfTeams, topic]);

  return (
    <>
      <Modal open={contactModal} setOpen={setContactModal}>
        <div className="text-lg">
          <span className="text-2xl">OIP Posejdon</span>
          <br />
          <span className="italic">Project Coordinator: </span>
          <span>Igor Spetič</span>
          <br />
          <span className="italic">Phone Number: </span>
          <a href="tel:385912861912" target="_blank" rel="noreferrer">
            <span className="text-blue-600 underline hover:text-blue-400 transition-colors">
              +385 91 286 1912
            </span>
          </a>
          <br />
          <span className="italic">e-mail: </span>
          <a
            href="mailto:speja@oip-posejdon.hr"
            target="_blank"
            rel="noreferrer"
          >
            <span className="text-blue-600 underline hover:text-blue-400 transition-colors">
              speja@oip-posejdon.hr
            </span>
          </a>
        </div>
        <Button
          className="bg-theme-olive w-full rounded h-10 mt-8 text-white"
          onClick={() => setContactModal(false)}
        >
          Okay
        </Button>
      </Modal>
      <div className="w-full h-[calc(100%-3.75rem)] relative">
        <div className="mx-auto w-full max-w-sm sm:max-w-3xl flex flex-col sm:flex-row justify-between mt-10">
          <DropdownMenu
            label="Select topic"
            values={topicsValues}
            selected={topic}
            setSelected={(value) => setTopic(value as TopicType)}
          />

          <div className="sm:mt-0 mt-10">
            <DropdownMenu
              label="Select map"
              values={mapsValues}
              selected={map}
              setSelected={(value) => setMap(value as MapType)}
              disabled={topic === "Project Design"}
            />
          </div>

          <div className="sm:mt-0 mt-10">
            <DropdownMenu
              label="Select No. of teams"
              values={teamsValues}
              selected={noOfTeams ? String(noOfTeams) : ""}
              setSelected={(value) => setNoOfTeams(Number(value))}
            />
          </div>
        </div>

        <div className="my-20">
          <div className="mx-auto hexagon">
            <img
              src="./icon_white.png"
              alt="NFE Box"
              className="absolute top-1/2 -translate-y-1/2 left-1/2 -translate-x-1/2"
            />
          </div>
        </div>

        <div className="absolute right-4 bottom-4">
          <Button
            className="bg-theme-dark-olive w-fit rounded h-10 text-white px-4 flex items-center"
            onClick={() => setContactModal(true)}
          >
            Contact <UserIcon className="w-6 h-6 ml-2" />
          </Button>
        </div>
      </div>
    </>
  );
};
